import gsap from 'gsap';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// @import constants
import { redirect_url } from 'config/endpoints';
// @import context
import AppContext from 'context/appContext';
// @import images
import icon_1 from 'images/icon-1.png';
import icon_3 from 'images/icon-3.png';
import icon_4 from 'images/icon-4.png';
import icon_5 from 'images/icon-5.png';
// @import styles
import 'css/components/menu.scss';

const Menu = () => {
	const { setMenuOpen } = useContext(AppContext);

	const handleButtonClick = () => {
		setMenuOpen(false);

		gsap.to('.wrapper.mobileFrame', {
			x: 0,
			duration: 0.2,
			onStart: () => {
				gsap.set('.menuWrapper', { autoAlpha: 1 });
			},
			onComplete: () => {
				gsap.set('.menuWrapper', { autoAlpha: 0 });
			}
		});
	};

	return (
		<div className="menuWrapper">
			<nav className="sideMenu">
				<ul>
					<li>
						<Link to={`/`} onClick={handleButtonClick}>
							<img src={icon_1} alt="" />
							<span>Jouw geldkaarten</span>
						</Link>
					</li>
					<li>
						<a
							href={redirect_url + 'gebruiksvoorwaarden'}
							onClick={handleButtonClick}
						>
							<img src={icon_3} alt="" />
							<span>Gebruikersvoorwaarden</span>
						</a>
					</li>
					<li>
						<a href={redirect_url + 'over-cheqi'} onClick={handleButtonClick}>
							<img src={icon_4} alt="" />
							<span>Over de geldkaart</span>
						</a>
					</li>
					<li>
						<Link to={`/contact`} onClick={handleButtonClick}>
							<img src={icon_5} alt="" />
							<span> Help & Contact</span>
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Menu;
