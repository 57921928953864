import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input';
import { useLocation, useHistory } from 'react-router-dom';
// @import constants
import {
	claimstatus_endpoint,
	issuerslist_endpoint,
	verification_endpoint,
	processclaim_endpoint,
	receiverinfo_endpoint
} from 'config/endpoints';
// @import utils
import { isEmail } from 'utils/isEmail';
import { getToken } from 'utils/temptoken';
import { parseCurrency } from 'utils/parseCurrency';
// @import components
import Loader from 'components/loader';
import TermCondition from 'components/termCondition';
import { getError } from './getError';
// @import styles
import 'css/pages/pickup.scss';
// @import icons
import { ReactComponent as RightArrow } from 'svg/arrowright.svg';

const Pickup = () => {
	const history = useHistory();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const bankListWrapper = useRef(null);
	const nextButton = useRef(null);

	const id = searchParams.get('id');

	const [showterms, setShowTerms] = useState(false);
	const [OTP, setOTP] = useState('');
	const [loading, setLoading] = useState(true);
	const [errorCode, SetErrorCode] = useState();
	const [redirect, SetRedirect] = useState({
		openPopup: false,
		message:
			'Om de geldkaart op te halen is het nodig om je te identificeren. Zo weten we zeker dat de geldkaart bij jou uitkomt. En we zijn dat wettelijk verplicht. Je identificeert je door 1 eurocent over te maken van je persoonlijke bankrekening.'
	});

	const [state, setState] = useState({
		email: '', // qwe@weq.gl
		bank: '',
		termsaccept: false,
		emailRequired: null,
		otpRequired: null,
		bankList: null,
		userData: null
	});

	const [error, setError] = useState({
		email: '',
		otp: '',
		bank: '',
		termsaccept: ''
	});

	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value
		});

		setError({
			...error,
			[evt.target.name]: ''
		});
	}

	const getVerificationStatus = (token, callback) => {
		axios
			.get(`${claimstatus_endpoint}${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				callback(res.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const initiateRequests = () => {
		getToken(id, response => {
			const { token } = response;
			// console.log('token', token);
			const config = {
				headers: {
					Authorization: `Bearer ${token}`
				}
			};

			axios
				.all(
					[
						axios.get(`${receiverinfo_endpoint}${id}`, config),
						axios.get(issuerslist_endpoint, config)
					],
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)
				.then(
					axios.spread((...responses) => {
						// console.log(responses[1].data);
						const { name, amount, needsEmail, description, needsAccessCode } =
							responses[0].data;

						setLoading(false);
						setState({
							...state,
							otpRequired: needsAccessCode,
							emailRequired: needsEmail,
							userData: {
								name,
								amount,
								description
							},
							bankList: responses[1].data.data.issuersLists
						});
					})
				)
				.catch(function (error) {
					console.log(error);
				});
		});
	};

	useEffect(() => {
		initiateRequests();
		// eslint-disable-next-line
	}, []);

	const handleSubmit = () => {
		setLoading(true);
		setError({
			...error,
			otp:
				OTP === state.otpRequired
					? ''
						? 'Je bent vergeten de ophaalcode in te vullen'
						: OTP.length < 4
						? 'De ophaalcode bestaat uit 4 cijfers'
						: ''
					: '',
			email: state.emailRequired
				? state.email === ''
					? 'Je bent vergeten je e-mailadres in te vullen'
					: isEmail(state.email)
					? ''
					: 'Je hebt een ongeldig e-mailadres ingevuld'
				: '',
			termsaccept: !state.termsaccept
				? 'Je hebt de gebruiksvoorwaarden nog niet geaccepteerd'
				: ''
		});

		if (!id || !state.termsaccept) {
			setLoading(false);
			return false;
		} else if (state.otpRequired && OTP === '' && OTP.length < 4) {
			setLoading(false);
			return false;
		} else if (state.emailRequired && state.email === '') {
			setLoading(false);
			return false;
		} else {
			getToken(id, response => {
				const { token } = response;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				getVerificationStatus(token, data => {
					const { claimed, bankaccountVerificationStatus } = data;

					if (!claimed) {
						axios
							.post(
								`${processclaim_endpoint}${id}`,
								{
									email: state.emailRequired ? state.email.toLowerCase() : ''
									// accessCode: state.otpRequired ? OTP : ''
								},
								config
							)
							.then(res => {
								if (res.data.resultCode !== 1000) {
									SetErrorCode(res.data.resultCode);
								}

								if (res.data.accountNeedsVerification) {
									SetRedirect({
										...redirect,
										openPopup: true
									});
								}

								if (res.data.succes && !res.data.accountNeedsVerification) {
									history.push(`/claimed?id=${searchParams.get('id')}`);
								}

								setLoading(false);
							})
							.catch(function (error) {
								console.log(error);
								setLoading(false);
							});
					}

					if (claimed) {
						const bankaccountNotVerified =
							!bankaccountVerificationStatus ||
							bankaccountVerificationStatus === 'new' ||
							bankaccountVerificationStatus === 'disapproved';

						const bankaccountVerified =
							bankaccountVerificationStatus === 'pending' ||
							bankaccountVerificationStatus === 'approved';

						if (bankaccountNotVerified) {
							setLoading(false);
							SetRedirect({
								...redirect,
								openPopup: true
							});
						}

						if (bankaccountVerified) {
							history.push(`/claimed?id=${searchParams.get('id')}`);
						}
					}
				});
			});
		}
	};

	const handleVerification = () => {
		setLoading(true);
		setError({
			...error,
			bank: state.bank === '' ? 'Je bent vergeten de bank te selecteren' : ''
		});

		if (!id || state.bank === '') {
			setLoading(false);
			return false;
		} else {
			let url = `${verification_endpoint}${id}?bic=${state.bank}`;

			getToken(id, response => {
				const { token } = response;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				axios
					.get(url, config)
					.then(res => {
						// console.log(res.data);
						setLoading(false);
						window.location.href = res.data.url;
					})
					.catch(function (error) {
						setLoading(false);
						console.log(error);
					});
			});
		}
	};

	const hideTermsCondition = () => {
		setShowTerms(false);
	};

	return (
		<div className="contentWrapper pickupScreen">
			{loading && <Loader />}

			{state.userData && (
				<>
					<Helmet>
						<title>Cheqi maakt geld geven simpel!</title>
					</Helmet>
					<div className="wrapper">
						<h1>
							Hoi {state.userData.name}, hier is je Geldkaart van{' '}
							{parseCurrency(state.userData.amount)}
						</h1>
						{state.userData.description && (
							<p>
								<em>{state.userData.description}</em>
							</p>
						)}
						<p>
							Je hebt een Geldkaart ontvangen. Accepteer onze voorwaarden om de
							Geldkaart op te halen. Zodra je de geldkaart hebt geaccepteerd
							ontvang je het geld de eerstvolgende werkdag op je rekening.
						</p>
						{state.emailRequired && (
							<>
								<p>
									We hebben jouw e-mailadres nodig om je te kunnen
									identificeren. Jouw e-mailadres blijft bij ons en we mailen je
									niet.
								</p>
								<div className="fieldSet">
									<label>E-mailadres</label>
									<input
										name="email"
										className="inputField"
										placeholder="Jouw e-mailadres"
										value={state.email}
										onChange={handleChange}
									/>
									{error.email && <span className="error">{error.email}</span>}
								</div>
							</>
						)}

						{state.otpRequired && (
							<>
								<p>
									Vul hieronder de ophaalcode in die je hebt gekregen van degene
									die je de geldkaart heeft gestuurd.
								</p>
								<div className="fieldSet">
									<label>Ophaalcode</label>
									<div className="inputField otpField">
										<OtpInput
											onChange={otp => {
												setOTP(otp);
												setError({
													...error,
													otp: ''
												});
											}}
											numInputs={4}
											value={OTP}
											inputStyle="inputStyles"
											separator={<span className="inputSeparator"></span>}
										/>
									</div>
									{error.otp && <span className="error">{error.otp}</span>}
								</div>
							</>
						)}

						<div className="fieldSet">
							<div className="checkboxWrapper">
								<input
									type="checkbox"
									id="checkbox"
									onChange={event => {
										setState({
											...state,
											termsaccept: event.target.checked
										});
										setError({
											...error,
											termsaccept: ''
										});
									}}
								/>
								<small />
								<label htmlFor="checkbox">
									{/* Ik accepteer de{' '}
									<a
										href="https://www.cheqi.nl/gebruiksvoorwaarden"
										onClick={e => {
											e.preventDefault();
											setShowTerms(true);
										}}
									>
										voorwaarden van Cheqi
									</a>{' '}
									en de{' '}
									<a href="https://onlinepaymentplatform.com/nl/privacy">
										voorwaarden van de betaalpartner van Cheqi
									</a>{' '}
									(Online Payment Platform). */}
									Ik accepteer de{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										className={'underlineText'}
										href="https://www.cheqi.nl/gebruiksvoorwaarden"
									>
										voorwaarden van Cheqi
									</a>{' '}
									de{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										className={'underlineText'}
										href="https://onlinepaymentplatform.com/nl/privacy"
									>
										Betaalpartner
									</a>{' '}
									en{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										className={'underlineText'}
										href="https://www.postnl.nl/algemene-voorwaarden/"
									>
										PostNL
									</a>
									.
								</label>
							</div>
							{error.termsaccept && (
								<span className="error">{error.termsaccept}</span>
							)}
						</div>

						{errorCode && (
							<span className="errorbox">{getError(errorCode)}</span>
						)}
					</div>

					<button onClick={handleSubmit} type="button" className="buttonStyle">
						verder
						<RightArrow />
					</button>
				</>
			)}

			{redirect.openPopup && (
				<div className="popupBox">
					<div ref={bankListWrapper} className="wrapper">
						<h2>Wil je je eerst identificeren?</h2>
						<p>{redirect.message}</p>
						<h2>Kies je bank</h2>
						<div className="bankList">
							{state.bankList &&
								state.bankList.map(item => (
									<button
										key={item.bic}
										className={`bankItem ${
											state.bank === item.bic ? 'active' : ''
										}`}
										onClick={() => {
											setState({
												...state,
												bank: item.bic
											});
										}}
									>
										<img src={item.thumbnail.url} alt="" />
										<span>{item.name}</span>
									</button>
								))}
						</div>
						<button
							type="button"
							className="otherbanks"
							onClick={() => {
								bankListWrapper.current.classList.add('active');
								setTimeout(() => {
									document
										.querySelector('.popupBox')
										.scrollTo(0, nextButton.current.offsetTop);
								}, 50);
							}}
						>
							Andere bank...
						</button>
						{error.bank && <span className="errorbox">{error.bank}</span>}
						<button
							ref={nextButton}
							onClick={handleVerification}
							type="button"
							className="buttonStyle"
						>
							verder
							<RightArrow />
						</button>
					</div>
					<span className="background" />
				</div>
			)}
			{showterms && <TermCondition hidePopup={hideTermsCondition} />}
		</div>
	);
};

export default Pickup;
