import React from 'react';
import { redirect_url } from 'config/endpoints';
import { ReactComponent as Cross } from 'svg/cross.svg';
import 'css/components/termscondition.scss';

const TermCondition = ({ hidePopup }) => {
	return (
		<div className="popupBox termsandcondition">
			<div className="wrapper">
				<iframe
					src={`${redirect_url}gebruiksvoorwaarden?mode=webview`}
					title="terms and conditions"
					frameBorder="0"
					allowFullScreen
				/>
				<button type="button" className="close" onClick={hidePopup}>
					<Cross />
				</button>
			</div>
			<span className="background" />
		</div>
	);
};

export default TermCondition;
