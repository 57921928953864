import React from 'react';
// @import components
import Header from '../../modules/header';
// @import constants
import { faqUrl, mailtoUrl } from 'config/urls';

const Contact = () => {
	return (
		<>
			<Header title="Help & contact" />
			<div className={'pageWrapper viewPagerWrapper'}>
				<p className={'pageContent'}>
					We maken het graag zo simpel mogelijk met Cheqi. Maar misschien heb je
					toch een vraag.
				</p>
				<a href={faqUrl}>
					<span className={'strongContent'}>Bekijk de veelgestelde vragen</span>
				</a>

				<p className={'pageContent'}>Staat jouw vraag er niet bij?</p>

				<a href={`mailto:${mailtoUrl}`}>
					<span className={'strongContent'}>Neem contact op</span>
				</a>
			</div>
		</>
	);
};

export default Contact;
