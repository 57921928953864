import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
// @import components
import Menu from 'components/Menu';
// @import utils
import { usePageChange } from 'utils/detectPageChange';

const Layout = props => {
	usePageChange();

	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);

	return (
		<div
			className={`mainContainer ${
				searchParams.get('mode') === 'webview' ? 'webviewMode' : ''
			}`}
		>
			<Helmet
				defaultTitle="Cheqi maakt geld geven simpel!"
				titleTemplate="%s | Cheqi"
			>
				{/* <link
					href="https://fonts.googleapis.com/css?family=DM+Sans:400,700&family=Kalam&display=swap"
					rel="stylesheet"
				/> */}
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				<link
					href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Kalam&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<main className="wrapper mobileFrame">{props.children}</main>
			<Menu />
		</div>
	);
};

export default Layout;
