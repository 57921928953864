import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
// @impory components
import PageData from 'modules/pageData';
// @import images
import contactImage from 'images/recipient.png';
// import { ReactComponent as Adduser } from 'svg/add-user.svg';

const RecieverName = forwardRef((props, ref) => {
	const [firstName, setFirstName] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [userContactDetails, setUserContact] = useState(null);

	useImperativeHandle(ref, () => ({
		isFilled() {
			return firstName !== '' || userContactDetails !== null;
		},
		getFirstName() {
			return firstName;
		},
		getUserDetails() {
			return userContactDetails;
		}
	}));

	useEffect(() => {
		props.setButtonState(firstName !== '' || userContactDetails !== null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName, userContactDetails]);

	// const getPhoneNumber = () => {
	// 	// todo: check if we can collect phone number via javascript
	// 	selectContactPhone().then(selection => {
	// 		if (!selection) {
	// 			return null;
	// 		}
	// 		let { contact, selectedPhone } = selection;
	// 		setUserContact({
	// 			firstName: contact.givenName,
	// 			lastName: contact.familyName,
	// 			email: contact.emails.length > 0 ? contact.emails[0].address : null,
	// 			zipCode:
	// 				contact.postalAddresses.length > 0
	// 					? contact.postalAddresses[0].postalCode
	// 					: null,
	// 			city:
	// 				contact.postalAddresses.length > 0
	// 					? contact.postalAddresses[0].city
	// 					: null,
	// 			street:
	// 				contact.postalAddresses.length > 0
	// 					? contact.postalAddresses[0].street
	// 					: null,
	// 			phone: selectedPhone.number
	// 				.replace(' ', '')
	// 				.replace('(', '')
	// 				.replace(')', '')
	// 		});
	// 		setFirstName(contact.givenName);
	// 	});
	// };

	// const requestContactPermission = async () => {
	// 	try {
	// 		const granted = await PermissionsAndroid.request(
	// 			PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
	// 			{
	// 				title: 'Toegang tot contacten?',
	// 				message: 'Geef Cheqi toegang tot je contacten.',
	// 				buttonNegative: 'Cancel',
	// 				buttonPositive: 'OK'
	// 			}
	// 		);
	// 		if (granted === PermissionsAndroid.RESULTS.GRANTED) {
	// 			console.log('granted');
	// 			getPhoneNumber();
	// 		} else {
	// 			console.log('denied');
	// 		}
	// 	} catch (err) {
	// 		console.warn(err);
	// 	}
	// };

	return (
		<div className={'viewPagerWrapper recieverNameWrapper'}>
			<PageData
				pageTitle={props.pageTitle}
				pageDescription={props.pageDescription}
			/>
			<img className={'image'} src={contactImage} alt="" />
			<div className={'contactWrapper'}>
				<input
					type="text"
					value={firstName}
					autoCorrect="false"
					placeholder="voornaam"
					className={'inputBox'}
					onChange={event => {
						setFirstName(event.target.value);
					}}
				/>
				{/* <button type="button" className={'contactBtn'} onClick={() => {}}>
					<Adduser className="contactIcon" />
				</button> */}
			</div>
		</div>
	);
});

export default RecieverName;
