let apiurl;
let appurl;
let redirectUrl;

switch (window.location.hostname) {
	case 'localhost':
		// apiurl = 'https://cheqiapiprodv2.azurewebsites.net/';
		apiurl = 'https://cheqiapiacc.azurewebsites.net/api/';
		appurl = 'https://accept.cheqi.app/';
		redirectUrl = 'https://accept.cheqi.nl/';
		break;
	case 'accept.cheqi.app':
		apiurl = 'https://cheqiapiacc.azurewebsites.net/api/';
		appurl = 'https://accept.cheqi.app/';
		redirectUrl = 'https://accept.cheqi.nl/';
		break;
	case 'cheqi.app':
		apiurl = 'https://api-v2.cheqi.nl/api/';
		appurl = 'https://cheqi.app/';
		redirectUrl = 'https://www.cheqi.nl/';
		break;
	case 'www2.cheqi.app':
		apiurl = 'https://api-v2.cheqi.nl/api/';
		appurl = 'https://www2.cheqi.app';
		redirectUrl = 'https://www.cheqi.nl/';
		break;
	default:
		apiurl = 'https://api-v2.cheqi.nl/api/';
		appurl = 'https://cheqi.app/';
		redirectUrl = 'https://www.cheqi.nl/';
}

export const app_url = appurl;

export const redirect_url = redirectUrl;

export const api_url = apiurl;

export const cors_url = 'https://cors-anywhere.herokuapp.com/';

export const cms_api_url =
	'https://api-eu-central-1.graphcms.com/v2/cknya5sluarg001wd382nbx06/master';

// api_url = `${cors_url}${api_url}`;

// user endpoints
export const temptoken_endpoint = `${api_url}User/token/`; // GET

// claim endpoints
export const receiverinfo_endpoint = `${api_url}Claim/receiver/`; // GET
export const processclaim_endpoint = `${api_url}Claim/receiver/`; // POST
export const verification_endpoint = `${api_url}Claim/receiver/verificationUrl/`; // POST
export const claimstatus_endpoint = `${api_url}Claim/receiver/status/`; // GET
export const notifysender_endpoint = `${api_url}Claim/sender/notify/`; // POST

// payment endpoints
export const issuerslist_endpoint = `${api_url}Payment/issuers`; // GET
export const paystatus_endpoint = `${api_url}Payment/status/`; // GET
export const couponpayment_endpoint = `${api_url}Payment/getcouponcheckouturl/`; // GET

// {{api_url}}pay/{{id}}/status`; payresult = paystatus_endpoint : done
// {{api_url}}issuers`; pickup = issuerslist_endpoint : done

// {{api_url}}claim/{{id}}/status claimed = claimstatus_endpoint : done
// {{api_url}}claim/{{id}}/notifysender`; claimed = notifysender_endpoint : done
// {{api_url}}claim/{{id}}/needscontact`; pickup = receiverinfo_endpoint : done
// {{api_url}}claim/{{id}}/needsaccesscode`; pickup = receiverinfo_endpoint : done
// {{api_url}}claim/{{id}}/description`;pickup = receiverinfo_endpoint : done
// {{api_url}}claim/{{id}}/form`; pickup = processclaim_endpoint : done
// {{api_url}}claim/{{id}}/verificationurl?bic={{issuerid}}; pickup = verification_endpoint : done

// {{api_url}}pay/sender/getcheckouturl/{{id}}/{issuerid}`;

export const registeruser_endpoint = `${api_url}User`; // introduction screen
export const authuser_endpoint = `${api_url}User/authenticate`; // to authenticate user multiple screens

// order endpoints
export const orderlist_endpoint = `${api_url}Order`; // home screen
export const specificOrder_endpoint = `${api_url}Order/`; // payment screen
export const createorder_endpoint = `${api_url}Order/order`; // create order screen
export const cancelorder_endpoint = `${api_url}Order/cancelorder/`; // cancel order screen
export const shareorder_endpoint = `${api_url}Order/shareorder/`; // share order screen
export const orderfirstdeliverydate_endpoint = `${api_url}Order/GetFirstDeliveryDate`; // Get first delivery date
export const orderpossibledeliverydate_endpoint = `${api_url}Order/GetPossibleDeliveryDates`; // Get possible delivery date
export const orderprices_endpoint = `${api_url}Order/GetOrderPrice`; // Get price overview

// payment screen
export const checkouturl_endpoint = `${api_url}Payment/getcheckouturl/`; // GET checkout url

// notification endpoints
export const getnotification_endpoint = `${api_url}PushNotification/getAllowNotification`; // notification screen
export const notification_endpoint = `${api_url}PushNotification/updateAllowNotification`; // notification screen
export const storenotification_endpoint = `${api_url}PushNotification`; // home screen

// address
export const address_endpoint = `${api_url}Address`; // Get Address

// products
export const productlist_endpoint = `${api_url}Product`; // Get Product list
export const product_endpoint = `${api_url}Product/`;
