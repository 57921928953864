import React from 'react';
// @import images
import UnderConstruction from 'images/underconstruction.jpg';

export default function Maintenance() {
	return (
		<div className="underConstruction">
			<img src={UnderConstruction} alt="" />
		</div>
	);
}
