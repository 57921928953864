import gsap from 'gsap';
import React, { useContext } from 'react';
// @import context
import AppContext from 'context/appContext';
// import components
import Button from './button';
// @import images
import { ReactComponent as Menu } from 'svg/menu.svg';

const MenuIcon = () => {
	const { isMenuOpen, setMenuOpen } = useContext(AppContext);

	return (
		<Button
			theme="white"
			style={styles.button}
			icon={<Menu className="iconStyle" />}
			onClick={() => {
				setMenuOpen(!isMenuOpen);
				gsap.to('.wrapper.mobileFrame', {
					x: !isMenuOpen ? 280 : 0,
					duration: 0.2,
					onStart: () => {
						if (!isMenuOpen) {
							gsap.set('.menuWrapper', { autoAlpha: 1 });
						}
					},
					onComplete: () => {
						if (isMenuOpen) {
							gsap.set('.menuWrapper', { autoAlpha: 0 });
						}
					}
				});
			}}
		/>
	);
};

const styles = {
	button: {
		width: '45px',
		height: '45px',
		padding: '10px'
	}
};

export default MenuIcon;
