import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @import components
import Button from 'modules/button';
import BackButton from 'modules/backIcon';
// import styles
import { buttonActiveColor, greenColor } from 'config/colors';
// @import images
// import sadLama from 'images/lama-sad.png';
// import happyLama from 'images/lama-happy.png';
// import waitingLama from 'images/lama-waiting.png';
import CheqiLogo from 'images/cheqi-postnl.png';

const getContent = status => {
	let description, title, image, background;
	switch (status) {
		case 'failed':
			title = 'Betaling mislukt'; // failed
			description =
				'Er ging iets mis bij het afrekenen. Je kunt opnieuw proberen te betalen.';
			// image = sadLama;
			background = '#f44336';
			break;
		case 'cancelled':
			title = 'Geannuleerd'; // cancelled
			description =
				'De betaling is niet gelukt. Het lijkt erop dat je de betaling hebt geannuleerd. Je kunt opnieuw proberen te betalen.';
			// image = sadLama;
			background = buttonActiveColor;
			break;
		case 'expired':
			title = 'Zzzzzzz……'; // expired
			description =
				'Je sessie is verlopen, omdat je te lang niets hebt gedaan. Je kunt opnieuw proberen te betalen.';
			// image = waitingLama;
			background = '#23bbe0';
			break;
		case 'pending':
			title = 'Nog even geduld'; // pending
			description =
				'Wij zijn je betaling aan het verwerken. Zodra je betaling is goedgekeurd ontvang je een bericht van ons.';
			// image = waitingLama;
			background = '#66C1C6';
			break;
		case 'completed':
			title = 'Gelukt!'; // completed
			description =
				'Het bedrag staat uiterlijk de eerstvolgende werkdag nadat de ontvanger het bedrag heeft opgehaald op de rekening van de ontvanger.';
			// image = happyLama;
			background = greenColor;
			break;
		default:
			title = 'Nog even geduld'; // None
			description =
				'Wij zijn je betaling aan het verwerken. Zodra je betaling is goedgekeurd ontvang je een bericht van ons.';
			// image = waitingLama;
			background = '#66C1C6';
			break;
	}
	return { title, description, image, background };
};

const PayResult = () => {
	const history = useHistory();
	const location = useLocation();

	const { status } = location.state;

	// const {status} = route.params;

	// console.log('payresult screen', orderId, status);

	return (
		<div className="payresultScreen">
			<div className={'headerWrapper'}>
				<div className={'headerRow'}>
					<img src={CheqiLogo} className="cheqiLogo" alt="" />
					<BackButton
						onPress={() => {
							history.push('/');
						}}
					/>
				</div>
			</div>

			<div
				className="pageContainer"
				style={{ backgroundColor: getContent(status).background }}
			>
				<div className="maxFrameSize">
					<div className={'contentWrapper'}>
						<strong className={'pagerTitle colorWhite'}>
							{getContent(status).title}
						</strong>
						<p className={'pagerDescription colorWhite'}>
							{getContent(status).description}
						</p>
					</div>

					{/* <img
						alt=""
						className={'statusImage'}
						src={getContent(status).image}
						style={{ objectFit: status === 'pending' ? 'cover' : 'contain' }}
					/> */}

					<Button
						text="Mijn geldkaarten"
						className={'nextButton'}
						onClick={() => {
							history.push('/');
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default PayResult;
