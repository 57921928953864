import md5 from 'md5';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
// @import constants
import {
	authuser_endpoint,
	temptoken_endpoint,
	registeruser_endpoint,
	storenotification_endpoint
} from 'config/endpoints';
// import { mailtoUrl } from 'config/urls';

const getUniqueId = () => {
	const unique_id = localStorage.getItem('unique_id') || uuid();
	localStorage.setItem('unique_id', unique_id);
	return unique_id;
};

export const uniqueId = getUniqueId();

export const logEvent = async (eventName, propertyObject = {}) => {
	// todo add web analytics
	console.log('eventName: ', eventName);
	// await analytics().logEvent(eventName, propertyObject);
};

export const getData = async key => {
	const value = localStorage.getItem(key);
	return value;
};

export const storeData = async (key, value) => {
	try {
		await localStorage.setItem(key, value);
	} catch (e) {}
};

export const removeData = async key => {
	try {
		await localStorage.removeItem(key);
	} catch (e) {}
};

export const registerUser = (callback = () => {}) => {
	const uniqueId = getUniqueId();
	// console.log('uniqueId', uniqueId);
	axios
		.get(`${temptoken_endpoint}${uniqueId}`)
		.then(function (res) {
			const {
				data: { token }
			} = res;

			const userData = JSON.stringify({
				username: uniqueId,
				password: md5(uniqueId),
				role: 'User'
			});

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			};

			axios
				.post(registeruser_endpoint, userData, config)
				.then(function (response) {
					const loginData = JSON.stringify({
						token: response.data.token,
						date: new Date()
					});
					storeData('loginData', loginData);
					callback(response.data.token);
				})
				.catch(function (error) {
					if (error.response.data.message.includes('already taken')) {
						authenticateUser(newToken => {
							callback(newToken);
							// console.log('new auth token', newToken);
						});
					}
				});
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const authenticateUser = (callback = () => {}) => {
	const uniqueId = getUniqueId();
	axios
		.get(`${temptoken_endpoint}${uniqueId}`)
		.then(function (res) {
			const {
				data: { token }
			} = res;

			// console.log('temptoken', token);

			const userData = JSON.stringify({
				username: uniqueId,
				password: md5(uniqueId)
			});

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			};

			axios
				.post(authuser_endpoint, userData, config)
				.then(function (response) {
					const loginData = JSON.stringify({
						token: response.data.token,
						date: new Date()
					});
					storeData('loginData', loginData);
					callback(response.data.token);
				})
				.catch(function (error) {
					console.log('auth error', error.response.data.message);
				});
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getUserToken = callback => {
	getData('loginData').then(response => {
		const data = JSON.parse(response);
		const date = new Date(data.date);
		const now = new Date();
		let diff = (now.getTime() - date.getTime()) / 1000;
		diff /= 60;

		if (Math.abs(Math.round(diff)) < 60) {
			callback(data.token);
		} else {
			authenticateUser(token => {
				callback(token);
			});
		}
	});
};

export const registerFCMtoken = (authToken, fcmToken) => {
	axios
		.put(
			storenotification_endpoint,
			{
				InstallationId: fcmToken
			},
			{
				headers: {
					Authorization: `Bearer ${authToken}`
				}
			}
		)
		.then(r => {
			// console.log('store token response', r.data);
			storeData('FCMToken', fcmToken);
		})
		.catch(e => console.log('store token error:', e));
};

export const getTempToken = callback => {
	const uniqueId = getUniqueId();
	axios
		.get(`${temptoken_endpoint}${uniqueId}`)
		.then(res => callback(res.data.token));
};

export const handleAlert = (
	title = 'Oops…',
	message = 'Er is iets misgegaan met Cheqi. Laat dit ons per e-mail weten, zodat wij je kunnen helpen.',
	buttonTitle = 'contact',
	callback = () => {
		const subject = 'vraag over Cheqi';
		const body = 'beschrijf hier het probleem dat jij ervaart met de Cheqi app';
		// todo open mail to send error
		// Linking.canOpenURL(`mailto:${mailtoUrl}`).then(supported => {
		// 	if (supported) {
		// 		Linking.openURL(`mailto:${mailtoUrl}`);
		// 		Linking.openURL(`mailto:${mailtoUrl}?subject=${subject}&body=${body}`);
		// 	} else {
		// 		console.log("Don't know how to open URI: " + `mailto:${mailtoUrl}`);
		// 	}
		// });
		console.log('subject:', subject);
		console.log('body:', body);
	},
	cancelCallback = () => {}
) => {
	// Alert.alert(title, message, [
	// 	{
	// 		text: 'annuleer',
	// 		onPress: cancelCallback,
	// 		style: 'cancel'
	// 	},
	// 	{ text: buttonTitle, onPress: callback }
	// ]);
};
