import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
// @impory components
import PageData from 'modules/pageData';
// @import images
import { ReactComponent as CheckMark } from 'svg/check.svg';

const CardMessage = forwardRef((props, ref) => {
	const [checked, SetChecked] = useState(false);
	const [cardMessage, setCardMessage] = useState('');

	useImperativeHandle(ref, () => ({
		isFilled() {
			return cardMessage !== '';
		},
		isChecked() {
			return checked;
		},
		getMessage() {
			return cardMessage;
		},
		resetComponent() {
			setCardMessage('');
		}
	}));

	useEffect(() => {
		props.setButtonState(cardMessage !== '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardMessage]);

	return (
		<>
			<div className={'viewPagerWrapper cardMessageWrapper'}>
				<PageData
					pageTitle={props.pageTitle}
					pageDescription={props.pageDescription}
				/>

				<textarea
					maxLength={500}
					value={cardMessage}
					autoCorrect={'false'}
					onChange={event => {
						setCardMessage(event.target.value);
					}}
					className={'inputBox'}
					placeholder={`Jouw bericht voor ${props.firstName}`}
				></textarea>

				<p className="pagerDescription">
					Wil je de geldkaart persoonlijk geven? Vraag dan voor 1 cent een extra
					enveloppe aan
				</p>

				<div className={'section'}>
					<button
						type="button"
						className={'acceptWrapper'}
						onClick={() => {
							SetChecked(!checked);
						}}
					>
						<div className={`checkBox ${checked ? 'active' : ''}`}>
							{checked && <CheckMark className="markIcon" />}
						</div>
						<label className={'pagerDescription'}>
							Stuur een extra enveloppe mee
						</label>
					</button>
				</div>
				{/* <Text style={styles.pagerDescription}>
          Als je niets wilt zeggen, dan kun je deze stap overslaan.
        </Text> */}
			</div>
		</>
	);
});

export default CardMessage;
