/* eslint-disable react/style-prop-object */
import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { FormattedNumber } from 'react-intl';
// @import components
import Modal from 'components/Modal';
// @import modules
import Button from 'modules/button';
import PageData from 'modules/pageData';

const amountsArray = ['5,00', '10,00', '15,00', '20,00', '25,00', '50,00'];

const CheqiAmount = forwardRef((props, ref) => {
	const [error, setError] = useState(false);

	const [amount, setAmount] = useState('');
	const [otherAmount, setOtherAmount] = useState('');
	const [modalVisible, setModalVisible] = useState(false);

	useImperativeHandle(ref, () => ({
		isFilled() {
			return amount !== '' || otherAmount !== '';
		},
		getAmount() {
			return otherAmount
				? parseFloat(otherAmount.replace(',', '.'))
				: parseFloat(amount.replace(',', '.'));
		}
	}));

	useEffect(() => {
		props.setButtonState(amount !== '' || otherAmount !== '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherAmount, amount]);

	useEffect(() => {
		if (
			(otherAmount !== '' && otherAmount.replace(',', '.') < 2) ||
			(otherAmount !== '' && otherAmount.replace(',', '.') > 100)
		) {
			setError(true);
		} else {
			setError(false);
		}
	}, [otherAmount]);

	return (
		<div className={'viewPagerWrapper cheqiAmountWrapper'}>
			<PageData
				pageTitle={props.pageTitle}
				pageDescription={props.pageDescription}
			/>

			<div className={'pricesButtonsWrapper'}>
				{amountsArray.map((item, i) => (
					<button
						type="button"
						key={`amount_${i}`}
						className={`button ${amount === item ? 'activeButton' : ''}`}
						onClick={() => {
							setAmount(item);
						}}
					>
						<span
							className={`buttonText ${
								amount === item ? 'activeButtonText' : ''
							}`}
						>
							€ {item}
						</span>
					</button>
				))}

				<button
					type="button"
					className={`button ${
						otherAmount && amount === '' ? 'activeButton' : ''
					}`}
					onClick={() => {
						setModalVisible(true);
						setAmount('');
					}}
				>
					<span className={`buttonText`}>
						anders{' '}
						{otherAmount && (
							<FormattedNumber
								value={otherAmount.replace(',', '.')}
								style="currency"
								currency="EUR"
							/>
						)}
					</span>
				</button>
			</div>

			<Modal handleClose={() => setModalVisible(false)} isOpen={modalVisible}>
				<div className={'modelWrapper amount-picker'}>
					<div className={'modalHeader'}>
						<strong className={'pagerTitle clearMargin'}>Kies je bedrag</strong>
					</div>
					<div className={'modalBody'}>
						<strong className={'pagerDescription'}>
							vul het bedrag van je keuze in
						</strong>
						<input
							type="text"
							maxLength="5"
							autoCorrect="false"
							className={'inputBox'}
							placeholder="bijv. 7,50 of 30,00"
							value={otherAmount.replace('.', ',')}
							onChange={event => {
								setOtherAmount(event.target.value);
							}}
						/>

						{error && (
							<p className={'errorText'}>
								Je kunt een bedrag tussen € 2,00 en € 100,00 kiezen
							</p>
						)}

						<div className={'buttonsWrapper modelButtonsWrapper'}>
							<Button
								theme="outline"
								text="annuleer"
								onClick={() => {
									if (otherAmount) {
										let tempNumber = Number(otherAmount.replace(',', '.'));
										if (tempNumber < 2) {
											setOtherAmount('');
										}
									}
									setModalVisible(false);
								}}
								className={'modelButton'}
							/>
							<Button
								text="ok"
								theme={error || otherAmount === '' ? 'disable' : 'secondary'}
								className={'modelButton'}
								onClick={() => {
									if (!error && otherAmount !== '') {
										setModalVisible(false);
									}
								}}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
});

export default CheqiAmount;
