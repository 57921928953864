import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { api_url } from 'config/endpoints';
// @import icons
import { ReactComponent as RightArrow } from 'svg/arrowright.svg';
// @import styles
import './index.scss';

const PayResult = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get('id');

	const [issuers, setIssuers] = useState(null);

	const [state, setState] = useState({
		bank: ''
	});

	const [error, setError] = useState({
		bank: ''
	});

	const nextButton = useRef(null);

	useEffect(() => {
		const issuersEndpoint = `${api_url}issuers`;
		axios
			.get(issuersEndpoint)
			.then(res => {
				setIssuers(res.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const handleVerification = () => {
		setError({
			...error,
			bank: state.bank === '' ? 'Je bent vergeten de bank te selecteren' : ''
		});

		if (!id || state.bank === '') {
			return false;
		} else {
			const url = `${api_url}pay/sender/getcheckouturl/${id}/${state.bank}`;
			axios
				.post(url, null, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
				.then(res => {
					if (res.status === 200) {
						window.location.href = res.data;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};

	return (
		<div className="contentWrapper verifySender">
			<div className="popupBox">
				<div
					// ref={bankListWrapper}
					className="wrapper"
				>
					<h1>Kies je bank</h1>
					<div className="bankList">
						{issuers &&
							issuers.map(item => (
								<button
									key={item.id}
									className={`bankItem ${
										state.bank === item.id ? 'active' : ''
									}`}
									onClick={() => {
										setState({
											...state,
											bank: item.id
										});
									}}
								>
									<img src={item.thumbnail} alt={item.name} />
								</button>
							))}
					</div>
					{error.bank && <span className="errorbox">{error.bank}</span>}
					<button
						type="button"
						ref={nextButton}
						className="buttonStyle"
						onClick={handleVerification}
					>
						volgende
						<RightArrow />
					</button>
				</div>
			</div>
		</div>
	);
};

export default PayResult;
