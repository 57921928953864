import React from 'react';
// @import constants
import {
	buttonActiveColor,
	buttonPrimaryColor,
	buttonSecondaryColor,
	buttonDisableColor,
	dotsColor,
	textColor,
	greenColor
} from 'config/colors';

const getButtonTheme = theme => {
	let buttonTheme = {
		color: 'white',
		bg: buttonSecondaryColor,
		underlay: buttonActiveColor
	};
	switch (theme) {
		case 'outline':
			buttonTheme.bg = 'transparent';
			buttonTheme.underlay = 'white';
			buttonTheme.color = buttonSecondaryColor;
			buttonTheme.borderColor = buttonSecondaryColor;
			break;
		case 'primary':
			buttonTheme.bg = buttonPrimaryColor;
			buttonTheme.underlay = dotsColor;
			buttonTheme.color = textColor;
			buttonTheme.borderColor = buttonPrimaryColor;
			break;
		case 'secondary':
			buttonTheme.bg = buttonSecondaryColor;
			buttonTheme.underlay = buttonActiveColor;
			buttonTheme.color = 'white';
			buttonTheme.borderColor = buttonSecondaryColor;
			break;
		case 'disable':
			buttonTheme.bg = buttonDisableColor;
			buttonTheme.underlay = buttonDisableColor;
			buttonTheme.color = 'rgba(0,0,0,0.5)';
			buttonTheme.borderColor = buttonDisableColor;
			break;
		case 'success':
			buttonTheme.bg = greenColor;
			buttonTheme.underlay = greenColor;
			buttonTheme.color = 'white';
			buttonTheme.borderColor = greenColor;
			break;
		case 'warning':
			buttonTheme.bg = '#f44336';
			buttonTheme.underlay = '#f44336';
			buttonTheme.color = 'white';
			buttonTheme.borderColor = '#f44336';
			break;
		case 'pending':
			buttonTheme.bg = '#66C1C6';
			buttonTheme.underlay = '#66C1C6';
			buttonTheme.color = 'white';
			buttonTheme.borderColor = '#66C1C6';
			break;
		case 'cancel':
			buttonTheme.bg = '#dc2f02';
			buttonTheme.underlay = '#dc2f02';
			buttonTheme.color = 'white';
			buttonTheme.borderColor = '#dc2f02';
			break;
		case 'white':
			buttonTheme.bg = 'white';
			buttonTheme.underlay = dotsColor;
			buttonTheme.color = textColor;
			buttonTheme.borderColor = buttonDisableColor;
			break;
		default:
			buttonTheme.bg = buttonSecondaryColor;
			buttonTheme.underlay = buttonActiveColor;
			buttonTheme.color = 'white';
			break;
	}
	return buttonTheme;
};

const index = ({
	text,
	icon,
	style,
	image,
	imgClass,
	className,
	imgStyle,
	textStyle,
	iconStyles,
	theme = 'secondary',
	buttonType = 'button',
	onClick = () => {}
}) => {
	return (
		<button
			type={buttonType}
			onClick={onClick}
			className={`button ${className}`}
			style={{
				...style,
				backgroundColor: getButtonTheme(theme).bg,
				borderColor: getButtonTheme(theme).borderColor
			}}
			// todo handle hover color
			// underlayColor={getButtonTheme(theme).underlay}
		>
			{text && (
				<span
					className={`btnText ${textStyle}`}
					style={{ color: getButtonTheme(theme).color }}
				>
					{text}
				</span>
			)}
			{icon && icon}
			{image && (
				<img style={imgStyle} className={`${imgClass}`} src={image} alt="" />
			)}
		</button>
	);
};

export default index;
