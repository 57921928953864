import React, {
	useRef,
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import axios from 'axios';
import moment from 'moment';
import localization from 'moment/locale/nl';
import { Calendar } from 'react-modern-calendar-datepicker';
// @impory components
import Modal from 'components/Modal';
// @import modules
import PageData from 'modules/pageData';
// @import constants
import {
	address_endpoint,
	orderfirstdeliverydate_endpoint,
	orderpossibledeliverydate_endpoint
} from 'config/endpoints';
// @import images
import { ReactComponent as CheckMark } from 'svg/check.svg';
import { ReactComponent as CalendarIcon } from 'svg/calendar.svg';
// @import utils
import { getUserToken } from 'utils/functions';
import { isEmail, isPhone } from 'utils/validationPresets';
// @import css
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

// previousTitle="vorig"
// nextTitle="volgende"
const myCustomLocale = {
	// months list by order
	months: [
		'januari',
		'februari',
		'maart',
		'april',
		'mei',
		'juni',
		'juli',
		'augustus',
		'september',
		'oktober',
		'november',
		'december'
	],

	// week days by order
	weekDays: [
		{
			name: 'Sunday', // used for accessibility
			short: 'zo', // displayed at the top of days' rows
			isWeekend: true // is it a formal weekend or not?
		},
		{
			name: 'Monday',
			short: 'ma'
		},
		{
			name: 'Tuesday',
			short: 'di'
		},
		{
			name: 'Wednesday',
			short: 'wo'
		},
		{
			name: 'Thursday',
			short: 'do'
		},
		{
			name: 'Friday',
			short: 'vr'
		},
		{
			name: 'Saturday',
			short: 'za',
			isWeekend: true
		}
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},

	// texts in the date picker
	nextMonth: 'volgende', // month
	previousMonth: 'vorig', // month
	openMonthSelector: 'Open Month Selector',
	openYearSelector: 'Open Year Selector',
	closeMonthSelector: 'Close Month Selector',
	closeYearSelector: 'Close Year Selector',
	defaultPlaceholder: 'Select...',

	// for input range value
	from: 'from',
	to: 'to',

	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false
};

const minimumDate = () => {
	const date = new Date();

	if (date.getHours() >= 20.5) {
		date.setDate(date.getDate() + 2);
	} else {
		date.setDate(date.getDate() + 2);
	}

	return {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate()
	};
};

const maximumDate = () => {
	const date = new Date();
	date.setMonth(date.getMonth() + 3);

	return {
		year: date.getFullYear(),
		month: date.getMonth(),
		day: date.getDate()
	};
};

function getSundays(year) {
	var date = new Date(year, 0, 1);
	while (date.getDay() !== 0) {
		date.setDate(date.getDate() + 1);
	}
	var days = [];
	while (date.getFullYear() === year) {
		var m = date.getMonth() + 1;
		var d = date.getDate();
		days.push(
			year + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d)
		);
		date.setDate(date.getDate() + 7);
	}
	return days;
}

function getMondays(year) {
	var date = new Date(year, 0, 1);
	while (date.getDay() !== 1) {
		date.setDate(date.getDate() + 1);
	}
	var days = [];
	while (date.getFullYear() === year) {
		var m = date.getMonth() + 1;
		var d = date.getDate();
		days.push(
			year + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d)
		);
		date.setDate(date.getDate() + 7);
	}
	return days;
}

const getDisableDays = () => {
	const newdate = new Date();

	const days = [
		...getMondays(newdate.getFullYear()).map(dateString => {
			const date = new Date(dateString);
			return {
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate()
			};
		}),
		...getSundays(newdate.getFullYear()).map(dateString => {
			const date = new Date(dateString);
			return {
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate()
			};
		})
	];
	return days;
};

const ContactDetails = forwardRef((props, ref) => {
	const [state, setState] = useState({
		// receiverFirstName: '',
		receiverLastName: props.userContactDetails?.lastName || '',
		zip: props.userContactDetails?.zipCode || '',
		// street: props.userContactDetails?.street || '',
		street: '',
		houseNumber: '',
		houseNumberAddition: '',
		city: props.userContactDetails?.city || '',
		receiverEmail: props.userContactDetails?.email || '',
		receiverPhone: props.userContactDetails?.phone || '',
		senderFirstName: '',
		senderEmail: '',
		deliveryDate: null
	});

	const [selectedDay, setSelectedDay] = useState(minimumDate());

	useEffect(() => {
		setState({
			...state,
			city: props.userContactDetails?.city || '',
			zip: props.userContactDetails?.zipCode || '',
			// street: props.userContactDetails?.street || '',
			receiverLastName: props.userContactDetails?.lastName || '',
			receiverEmail: props.userContactDetails?.email || '',
			receiverPhone: props.userContactDetails?.phone || ''
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userContactDetails]);

	const zipRef = useRef(null);
	const houseNumberRef = useRef(null);
	const houseNumberAdditionRef = useRef(null);
	// const receiverEmailRef = useRef(null);
	const recieverNumberRef = useRef(null);
	const senderNameRef = useRef(null);
	const senderEmailRef = useRef(null);

	const [validationError, setValidationError] = useState({
		senderEmailError: '',
		receiverEmailError: '',
		receiverNumberError: ''
	});
	const [error, setError] = useState('');
	const [show, setShow] = useState(false);
	const [checked, SetChecked] = useState(false);
	const [editAddress, SetEditAddress] = useState(false);
	const [addressError, SetAddressError] = useState(false);

	useEffect(() => {
		// setShow(false);
		getUserToken(token => {
			axios
				.get(orderfirstdeliverydate_endpoint, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					setState({
						...state,
						deliveryDate: new Date(moment(response.data, 'YYYYMMDD'))
					});
				})
				.catch(err => {
					console.log('get first delivery date error:', err);
				});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onChange(selectedDay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDay]);

	const onChange = selectedDate => {
		const dateString = new Date(
			selectedDate.year + '/' + selectedDate.month + '/' + selectedDate.day
		);
		const currentDate = dateString || state.deliveryDate;
		getUserToken(token => {
			axios
				.get(
					`${orderpossibledeliverydate_endpoint}?requestedDeliveryDate=${moment(
						currentDate
					).format('YYYYMMDD')}`,
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(response => {
					if (response.data.possibleDates[0].matchType === 'possible') {
						setState({
							...state,
							deliveryDate: new Date(
								moment(response.data.possibleDates[0].deliveryDate, 'YYYYMMDD')
							)
						});
						setShow(false);
						setError('');
					} else {
						const errorMessage = `PostNL kan de geldkaart bezorgen op ${moment(
							response.data.possibleDates[0].deliveryDate,
							'YYYYMMDD'
						).format('DD-M-YYYY')} of ${moment(
							response.data.possibleDates[1].deliveryDate,
							'YYYYMMDD'
						).format('DD-M-YYYY')}`;
						setError(errorMessage);
					}
				})
				.catch(err => {
					console.log('get possible delivery date error:', err);
				});
		});
	};

	useImperativeHandle(ref, () => ({
		isFilled() {
			const {
				receiverLastName,
				zip,
				street,
				houseNumber,
				city,
				receiverEmail,
				receiverPhone,
				senderFirstName,
				senderEmail
			} = state;

			return (
				receiverLastName &&
				zip &&
				street &&
				houseNumber &&
				city &&
				receiverEmail &&
				receiverPhone &&
				senderFirstName &&
				senderEmail &&
				checked
			);
		},

		validate() {
			return handleValidation();
		},

		getData() {
			return state;
		}
	}));

	const handleValidation = () => {
		if (
			isEmail(state.receiverEmail) &&
			isEmail(state.senderEmail) &&
			isPhone(state.receiverPhone)
		) {
			return true;
		} else {
			setValidationError({
				senderEmailError: isEmail(state.senderEmail)
					? ''
					: 'Geef een geldig e-mailadres op',
				receiverEmailError: isEmail(state.receiverEmail)
					? ''
					: 'Geef een geldig e-mailadres op',
				receiverNumberError: isPhone(state.receiverPhone)
					? ''
					: 'Geef een geldig telefoonnummer op (06-12345678)'
			});
			return false;
		}
	};

	const handleChange = (event, inputName) => {
		const value = event.target.value;
		setState({
			...state,
			[inputName]: value
		});
		if (inputName === 'receiverEmail') {
			if (isEmail(value) && validationError.receiverEmailError !== '') {
				setValidationError({
					...validationError,
					receiverEmailError: ''
				});
			}
		}
		if (inputName === 'senderEmail') {
			if (isEmail(value) && validationError.senderEmailError !== '') {
				setValidationError({
					...validationError,
					senderEmailError: ''
				});
			}
		}
		if (inputName === 'receiverPhone') {
			if (isPhone(value) && validationError.receiverNumberError !== '') {
				setValidationError({
					...validationError,
					receiverNumberError: ''
				});
			}
		}
	};

	// useEffect(() => {
	//   if (state.zip) {
	//     getUserToken(getAddress);
	//   }
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [state.zip, state.houseNumber]);

	useEffect(() => {
		const {
			receiverLastName,
			zip,
			street,
			houseNumber,
			// houseNumberAddition,
			city,
			receiverEmail,
			receiverPhone,
			senderFirstName,
			senderEmail
		} = state;

		if (
			receiverLastName &&
			zip &&
			street &&
			houseNumber &&
			// houseNumberAddition &&
			city &&
			receiverEmail &&
			receiverPhone &&
			senderFirstName &&
			senderEmail &&
			checked
		) {
			props.setButtonState(true);
		} else {
			props.setButtonState(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, checked]);

	const getAddress = token => {
		axios
			.get(`${address_endpoint}`, {
				params: {
					zipCode: state.zip,
					number: state.houseNumber
				},
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(response => {
				console.log('getAddress response: ', response.data);
				if (response.data.street == null) {
					axios
						.get(
							`https://productprijslokatie.postnl.nl/address-widget/api/lookup/address?houseNumber=${state.houseNumber}&houseNumberSuffix=&postalCode=${state.zip}`
						)
						.then(response => {
							console.log('free address response:', response);
							setState({
								...state,
								street: response.data.address.street,
								city: response.data.address.city
							});
							SetAddressError(false);
						})
						.catch(error => {
							console.log('error:', error);
							SetAddressError(true);
						});
				} else {
					SetAddressError(false);
				}
				setState({
					...state,
					street: response.data.street,
					city: response.data.town
				});
			})
			.catch(err => {
				console.log('get address error:', err);
			});
	};

	const handleInputReturnKey = (key, element, type = 'focus') => {
		if (key === 'Enter') {
			if (type === 'focus') {
				element.focus();
			} else {
				element.blur();
			}
		}
	};

	return (
		<div className={'viewPagerWrapper contactDetailsWrapper'}>
			<PageData
				pageTitle={props.pageTitle}
				pageDescription={props.pageDescription}
			/>

			<div className={'section'}>
				<strong className={'sectionTitle'}>Bezorgadres</strong>
				<div className={'row'}>
					<input
						type="text"
						autoCorrect="false"
						name="receiverLastName"
						className={'inputBox'}
						placeholder="Achternaam"
						value={state.receiverLastName}
						onChange={e => handleChange(e, 'receiverLastName')}
						onKeyPress={e => handleInputReturnKey(e.key, zipRef.current)}
					/>
				</div>
				{editAddress && (
					<div className={'row marginTop'}>
						<input
							type="text"
							autoCorrect="false"
							name="street"
							className={'inputBox'}
							placeholder="Straat"
							value={state.street}
							onChange={e => handleChange(e, 'street')}
							onKeyPress={e => handleInputReturnKey(e.key, e.target, 'blur')}
						/>
					</div>
				)}
				<div className={'row marginTop'}>
					{/*
						keyboardType={state.zip.length >= 4 ? 'default' : 'numeric'}
					*/}

					<input
						type="text"
						ref={zipRef}
						name="street"
						autoCorrect="false"
						className={'inputBox'}
						placeholder="Postcode"
						value={state.zip.toUpperCase()}
						onChange={e => handleChange(e, 'zip')}
						onKeyPress={e =>
							handleInputReturnKey(e.key, houseNumberRef.current)
						}
						style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
						onBlur={() => {
							if (state.houseNumber && state.zip) {
								getUserToken(getAddress);
							}
						}}
					/>

					<input
						type="number"
						ref={houseNumberRef}
						autoCorrect="false"
						name="houseNumber"
						className={'inputBox marginLeft'}
						placeholder="Huisnr."
						value={state.houseNumber}
						onChange={e => handleChange(e, 'houseNumber')}
						onKeyPress={e =>
							handleInputReturnKey(e.key, houseNumberAdditionRef.current)
						}
						style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
						onBlur={() => {
							if (state.houseNumber && state.zip) {
								getUserToken(getAddress);
							}
						}}
					/>

					<input
						type="text"
						ref={houseNumberAdditionRef}
						autoCorrect="false"
						name="houseNumberAddition"
						className={'inputBox marginLeft'}
						placeholder="Toev."
						value={state.houseNumberAddition}
						onChange={e => handleChange(e, 'houseNumberAddition')}
						onKeyPress={e =>
							handleInputReturnKey(
								e.key,
								houseNumberAdditionRef.current,
								'blur'
							)
						}
						style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
						onBlur={() => {
							if (state.houseNumber && state.zip) {
								getUserToken(getAddress);
							}
						}}
					/>
				</div>
				{editAddress && (
					<div className={'row marginTop'}>
						<input
							type="text"
							autoCorrect="false"
							name="city"
							className={'inputBox'}
							placeholder="Plaats"
							value={state.city}
							onChange={e => handleChange(e, 'city')}
							onKeyPress={e => handleInputReturnKey(e.key, e.target, 'blur')}
						/>
					</div>
				)}
				{state.zip && state.houseNumber && state.street ? (
					<p className={'pagerDescription marginTop'}>
						{`${state.street} ${state.houseNumber} ${state.houseNumberAddition}, ${state.city}`}{' '}
						<span
							className={'underlineText'}
							onClick={() => {
								SetEditAddress(!editAddress);
							}}
						>
							{editAddress ? 'Annuleer' : 'Wijzig'}
						</span>
					</p>
				) : null}

				{addressError && (
					<span className={'errorText marginTop'}>
						* Combinatie huisnummer en postcode onbekend. Probeer opnieuw.
					</span>
				)}
			</div>

			<div className={'section'}>
				<strong className={'sectionTitle'}>Wanneer?</strong>
				<p className={['pagerDescription clearMargin']}>
					Selecteer de bezorgdatum van de geldkaart
				</p>
				<button
					type="button"
					className={'dateButton marginTop'}
					onClick={() => {
						setShow(true);
					}}
				>
					<span className={'inputBox'}>
						{moment(state.deliveryDate)
							.locale('nl', localization)
							.format('DD MMMM YYYY')}
					</span>
					<div className={'dateIcon'}>
						<CalendarIcon className="calendarIcon" />
					</div>
				</button>

				<Modal handleClose={() => setShow(false)} isOpen={show}>
					<div className={'calendarWrapper'}>
						<div className={'calendar'}>
							<Calendar
								value={selectedDay}
								disabledDays={getDisableDays()}
								minimumDate={minimumDate()}
								maximumDate={maximumDate()}
								onChange={setSelectedDay}
								shouldHighlightWeekends={false}
								locale={myCustomLocale}
							/>

							<span className={'errorText'}>
								* Voor 19.30 besteld, dezelfde dag verzonden.
							</span>
							{error ? <span className={'errorText'}>{error}</span> : null}
						</div>

						<button
							type="button"
							onClick={() => {
								setError('');
								setShow(false);
							}}
							className={'calendarBackground'}
						></button>
					</div>
				</Modal>
			</div>

			<div className={'section'}>
				<strong className={'sectionTitle'}>
					Gegevens van {props.receiverFirstName}
				</strong>
				<p className={'pagerDescription clearMargin'}>
					Wij hebben het e-mailadres en telefoonnummer van{' '}
					<strong className={'boldText'}>{props.receiverFirstName}</strong>{' '}
					nodig om het bedrag veilig over te maken
				</p>

				<input
					type="email"
					autoCorrect="false"
					name="receiverEmail"
					className={'inputBox marginTop'}
					placeholder="E-mailadres"
					value={state.receiverEmail.toLowerCase()}
					onChange={e => handleChange(e, 'receiverEmail')}
					onKeyPress={e =>
						handleInputReturnKey(e.key, recieverNumberRef.current)
					}
				/>
				{validationError.receiverEmailError !== '' && (
					<span className={'errorText marginTop'}>
						* {validationError.receiverEmailError}
					</span>
				)}
				<input
					type="number"
					autoCorrect="false"
					name="receiverPhone"
					ref={recieverNumberRef}
					className={'inputBox marginTop'}
					placeholder="Telefoon"
					value={state.receiverPhone}
					onChange={e => handleChange(e, 'receiverPhone')}
					onKeyPress={e => handleInputReturnKey(e.key, senderNameRef.current)}
				/>
				{validationError.receiverNumberError !== '' && (
					<span className={'errorText marginTop'}>
						* {validationError.receiverNumberError}
					</span>
				)}
			</div>

			<div className={'section'}>
				<strong className={'sectionTitle'}>Jouw gegevens</strong>
				<p className={'pagerDescription'}>
					Wij hebben jouw e-mailadres nodig om je een bevestiging van je
					bestelling te sturen.
				</p>

				<input
					type="text"
					autoCorrect="false"
					name="senderFirstName"
					ref={senderNameRef}
					className={'inputBox marginTop'}
					placeholder="jouw voornaam"
					value={state.senderFirstName}
					onChange={e => handleChange(e, 'senderFirstName')}
					onKeyPress={e => handleInputReturnKey(e.key, senderEmailRef.current)}
				/>

				<input
					type="email"
					autoCorrect="false"
					name="senderEmail"
					ref={senderEmailRef}
					className={'inputBox marginTop'}
					placeholder="jouw e-mailadres"
					value={state.senderEmail.toLowerCase()}
					onChange={e => handleChange(e, 'senderEmail')}
					onKeyPress={e => handleInputReturnKey(e.key, e.target, 'blur')}
				/>
				{validationError.senderEmailError !== '' && (
					<span className={'errorText marginTop'}>
						* {validationError.senderEmailError}
					</span>
				)}
			</div>

			<div className={'section'}>
				<button
					type="button"
					className={'acceptWrapper'}
					onClick={() => {
						SetChecked(!checked);
					}}
				>
					<div className={`checkBox ${checked ? 'active' : ''}`}>
						{checked && <CheckMark className="markIcon" />}
					</div>
					<p className={'pagerDescription termsText'}>
						Ik accepteer de{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={'underlineText'}
							href="https://www.cheqi.nl/gebruiksvoorwaarden"
						>
							voorwaarden van Cheqi
						</a>{' '}
						de{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={'underlineText'}
							href="https://onlinepaymentplatform.com/nl/privacy"
						>
							Betaalpartner
						</a>{' '}
						en{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className={'underlineText'}
							href="https://www.postnl.nl/algemene-voorwaarden/"
						>
							PostNL
						</a>
						.
					</p>
				</button>
			</div>
		</div>
	);
});

export default ContactDetails;
