/* eslint-disable react/style-prop-object */
import React from 'react';
import { Grid, FreeMode, Navigation, Pagination } from 'swiper';
import { FormattedNumber } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
// import components
import PageData from 'modules/pageData';
// @import utils
import { logEvent, uniqueId } from 'utils/functions';
// @import styles
import 'swiper/modules/grid/grid.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

let flag = 0;

const logAnalytics = async (isDigital, cardTitle, cardCode) => {
	logEvent(
		isDigital ? 'select_card_physical_cheqi' : 'select_gift_physical_cheqi',
		{
			user_id: uniqueId,
			description: isDigital
				? 'Select card physical Cheqi'
				: 'Select gift physical Cheqi',
			card_title: cardTitle,
			card_code: cardCode
		}
	);
	flag = 1;
};

class SelectCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeitem: null
		};

		this.childsRef = [];
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.props.pageDescription !== nextProps.pageDescription ||
			this.state.activeIndex !== nextState.activeIndex
		) {
			return true;
		} else {
			return false;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.activeitem !== this.state.activeitem) {
			this.props.setButtonState(this.state.activeitem !== null);
			flag = 0;
			if (flag === 0 && this.state.activeitem) {
				logAnalytics(
					this.state.activeitem.digitalProduct,
					this.state.activeitem.cmsTitle,
					this.state.activeitem.productCode
				);
			}
		}
	}

	isFilled = () => {
		return this.state.activeitem;
	};

	getCard = () => {
		return this.state.activeitem;
	};

	resetComponent = () => {
		this.setState({
			activeIndex: null,
			activeitem: null
		});
	};

	render() {
		return (
			<div className={'viewPagerWrapper cardsWrapper'}>
				<PageData
					pageTitle={this.props.pageTitle}
					pageDescription={this.props.pageDescription}
				/>
				<div className="cardListWrapper">
					<Swiper
						pagination={true}
						navigation={true}
						slidesPerView={2}
						grid={{
							rows: 2
						}}
						spaceBetween={10}
						freeMode={true}
						modules={[FreeMode, Grid, Navigation, Pagination]}
						className="mySwiper"
					>
						{this.props.data.map((item, index) => (
							<SwiperSlide key={`cards_${index}`}>
								<button
									type="button"
									className={`cardContainer ${
										this.state.activeIndex === index ? 'activeCard' : ''
									}`}
									onClick={event => {
										if (this.state.activeIndex === index) {
											return;
										}

										this.setState({
											activeIndex: index,
											activeitem: item
										});
									}}
								>
									<>
										<img
											alt=""
											ref={Element => {
												this.childsRef[index] = Element;
												return;
											}}
											className={'cardImage'}
											src={`https://media.graphcms.com/resize=w:600,h:800,fit:crop/quality=value:70/compress/${item.cmsImageHandle}`}
										/>
										{item.price !== 0 && (
											<span className={'cardPrice'}>
												<FormattedNumber
													value={item.price}
													style="currency"
													currency="EUR"
												/>
												*
											</span>
										)}
										<span className={'cardTitle'}>{item.cmsTitle}</span>
									</>
								</button>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		);
	}
}

export default SelectCard;
