import React from 'react';
// import components
import Button from './button';
// @import images
import { ReactComponent as Back } from 'svg/left-arrow.svg';

const BackIcon = ({ onPress }) => {
	const handleBackPress = () => {
		// todo: go back to home screen
	};
	return (
		<Button
			icon={<Back className="iconStyle" />}
			theme="white"
			imgStyle={styles.imgStyle}
			style={styles.button}
			onClick={() => {
				onPress ? onPress() : handleBackPress();
			}}
		/>
	);
};

const styles = {
	button: {
		width: '45px',
		height: '45px',
		padding: 0
	},
	imgStyle: {
		width: '16px',
		transform: 'rotate(180deg)'
	}
};

export default BackIcon;
