export const getError = code => {
	let errorValue = '';
	switch (code) {
		case 2001: // 2001, Order status needs to be 'New' or 'SenderShared'
			errorValue = 'Deze geldkaart is al opgehaald'; // 'Cheqi already claimed and verified'; wrong
			break;
		case 2002:
			errorValue = 'De ophaalcode klopt niet'; // 'Access code mismatches';
			break;
		case 2003:
			errorValue = 'Je hebt niet alle benodigde gegevens ingevuld'; // 'Incomplete form or unknown';
			break;
		case 2004:
			errorValue = 'Betaling is niet gelukt'; // 'Failed to create merchant';
			break;
		case 2005:
			errorValue = 'De verzender heeft de geldkaart geannuleerd'; // 'Sender cancelled the payment';
			break;
		case 2006:
			errorValue = 'Deze geldkaart is al opgehaald'; // 'shipped and claimed'; : need to replace message
			break;
		default:
			errorValue = 'No error';
			break;
	}
	return errorValue;
};
