// @import packages
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/nl';
import { IntlProvider } from 'react-intl';
import TagManager from 'react-gtm-module';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import Layout from './components/layout';
import Maintenance from './components/Maintenance';
// import { redirect_url } from './config/endpoints';
// @import utils
import cms from './utils/cms';
import { registerUser } from './utils/functions';
// @import context
import { Provider } from './context/appContext';
// @import styles
import './App.scss';

const query = `{
  appBanners(stage: PUBLISHED) {
    backgroundColor
    offerText
    summary
    title
  }
}`;

// let haveToken;

const tagManagerArgs = {
	gtmId: 'GTM-MC37KRG'
};

const App = props => {
	const maintenance = Maintenance;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [isMenuOpen, setMenuOpen] = useState(false);

	const contextData = {
		bannerData: data,
		isMenuOpen,
		setMenuOpen
	};

	const { routes, location = { pathname: '/', key: '' } } = props;

	if (location.pathname === '/') {
		// window.location.href = redirect_url;
	}

	useEffect(() => {
		fetchBannerData();
		handleRegister();

		TagManager.initialize(tagManagerArgs);
	}, []);

	const fetchBannerData = () => {
		cms(query).then(response => {
			setData(response.data.appBanners);
		});
	};

	const handleRegister = () => {
		// haveToken = false;
		const value = localStorage.getItem('loginData');
		if (value === null) {
			registerUser(token => {
				if (token) {
					// haveToken = true;
					setLoading(false);
				}
			});
		} else {
			// haveToken = true;
			setLoading(false);
		}
	};
	// todo: show loader until haveToken set to true

	return (
		<Provider value={contextData}>
			<IntlProvider locale="nl" defaultLocale="en">
				{!loading && (
					<Layout>
						<Switch location={location}>
							{routes.map((route, index) => {
								console.log('route', route.isVisible);
								return (
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										render={props => {
											return React.createElement(
												route.isVisible ? route.component : maintenance,
												{
													...props
												}
											);
										}}
									/>
								);
							})}

							<Redirect from="*" to="/404" />
						</Switch>
					</Layout>
				)}
			</IntlProvider>
		</Provider>
	);
};

export default withRouter(App);
