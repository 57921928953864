/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';
// @import components
import Button from './button';
// @import svgs
import { ReactComponent as Check } from 'svg/check.svg';
import { ReactComponent as Cancel } from 'svg/cancel.svg';
import { ReactComponent as RightArrow } from 'svg/right-arrow.svg';

// New, = klaar om te versturen
// SenderCancelled, = geannuleerd
// SenderShared, = verstuurd
// ReceiverNotClaimed,
// ReceiverClaimed, = te betalen
// SenderNotPayed, = te betalen
// SenderPaymentCreated, = Betaling wordt verwerkt
// SenderPayed, = betaald
// OrderShipped = Verzonden

const getButtonTitle = (value, digitalOrder) => {
	let title = '';
	let theme = '';
	let icon = null;
	switch (value) {
		case 'New':
			title = 'klaar om te versturen';
			theme = 'secondary';
			icon = <RightArrow />;
			break;
		case 'SenderCancelled':
			title = 'geannuleerd';
			theme = 'cancel';
			icon = <Cancel />;
			break;
		case 'SenderShared':
			title = 'verstuurd';
			theme = 'pending';
			icon = <Check />;
			break;
		case 'ReceiverClaimed':
			title = digitalOrder ? 'wachten op ontvanger' : 'opgehaald';
			theme = digitalOrder ? 'pending' : 'success';
			icon = digitalOrder ? <Check /> : <Check />;
			break;
		case 'ReceiverClaimedVerified':
			title = digitalOrder ? 'te betalen' : 'opgehaald';
			theme = digitalOrder ? 'warning' : 'success';
			icon = digitalOrder ? <RightArrow /> : <Check />;
			break;
		case 'SenderPaymentCreated':
			title = 'betaling wordt verwerkt';
			theme = 'success';
			break;
		case 'SenderPayed':
			title = 'betaald';
			theme = 'success';
			icon = <Check />;
			break;
		case 'SenderNotPayed':
			title = 'te betalen';
			theme = 'warning';
			break;
		case 'OrderShipped':
			title = 'verzonden';
			theme = 'success';
			icon = <Check />;
			break;
		default:
			title = 'klaar om te versturen';
			theme = 'secondary';
			icon = <RightArrow />;
			break;
	}
	return { title, theme, icon };
};

const OrderCard = ({ item }) => {
	const history = useHistory();

	const handleNavigate = () => {
		if (
			(item.digitalOrder && item.status === 'ReceiverClaimedVerified') ||
			item.status === 'SenderNotPayed'
		) {
			history.push({
				pathname: '/pay-cheqi',
				state: { orderId: item.orderId }
			});
		} else if (
			(!item.digitalOrder && item.status === 'ReceiverClaimed') ||
			(!item.digitalOrder && item.status === 'ReceiverClaimedVerified') ||
			item.status === 'SenderPayed' ||
			item.status === 'OrderShipped'
		) {
			history.push({
				pathname: '/pay-result',
				search: `?id=${item.orderId}`,
				state: { status: item.transactionStatus, orderId: item.orderId }
			});
		} else {
			// history.push({
			// 	pathname: '/send-cheqi',
			// 	state: { data: item }
			// });
		}
	};

	const getCardTitle = () => {
		let title = '';
		if (item.digitalOrder) {
			title = item.senderMessage || 'Cheqi';
		} else {
			title = item.receiverName;
		}
		return title;
	};

	return (
		<div onClick={handleNavigate} className={'boxContainer'}>
			<div className={'cheqiBox'}>
				<p className={'cheqiTitle'}>{getCardTitle()}</p>
				{item.digitalOrder && (
					<p className={'cheqiAmount'}>
						<FormattedNumber
							value={item.amount}
							style="currency"
							currency="EUR"
						/>
					</p>
				)}
			</div>
			<div className={'separator'} />
			<div className={'cheqiBox'}>
				<p className={'cheqiDate'}>
					{new Date(item.createdDate).toLocaleDateString('nl')}
				</p>
				<Button
					theme={getButtonTitle(item.status, item.digitalOrder).theme}
					text={getButtonTitle(item.status, item.digitalOrder).title}
					icon={getButtonTitle(item.status, item.digitalOrder).icon}
					textStyle={'canceledpStyle'}
					className={'cheqiButton'}
					onClick={handleNavigate}
				/>
			</div>
		</div>
	);
};

export default OrderCard;
