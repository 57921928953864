import React from 'react';
// @import constants
import { dotsColor, secondaryColor } from 'config/colors';

const stepsProgress = ({ currentStep, totalSteps }) => {
	// console.log('currentStep', currentStep);
	const stepsWidth = (100 / totalSteps).toFixed(2);
	return (
		<div style={pageStyles.stepsWrapper}>
			<div
				style={{
					...pageStyles.stepsFilled,
					width: `${(currentStep + 1) * stepsWidth}%`
				}}
			/>
		</div>
	);
};

const pageStyles = {
	stepsWrapper: {
		height: '4px',
		backgroundColor: dotsColor
	},
	stepsFilled: {
		height: '100%',
		backgroundColor: secondaryColor
	}
};

export default stepsProgress;
