import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { couponpayment_endpoint } from 'config/endpoints';
// @import components
import Loader from 'components/loader';
// @import utils
import { getToken } from 'utils/temptoken';

const CouponPayment = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const id = searchParams.get('id');

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			getToken(id, response => {
				const { token } = response;
				axios
					.get(`${couponpayment_endpoint}${id}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(res => {
						setLoading(false);
						console.log('res', res.data);
						if (res.data.succes) {
							window.location.href = res.data.url;
						}
					})
					.catch(function (error) {
						setLoading(false);
						console.log('error', error);
					});
			});
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="contentWrapper claimedScreen">{loading && <Loader />}</div>
	);
};

export default CouponPayment;
