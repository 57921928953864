import React, {
	useRef,
	useState,
	useEffect,
	// useContext,
	useCallback
} from 'react';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// @import components
import Button from 'modules/button';
import Loading from 'modules/loading';
import BackButton from 'modules/backIcon';
// import HeaderBanner from 'modules/headerBanner';
import StepProgress from 'modules/stepsProgress';
// @import context
// import AppContext from 'context/appContext';
// @import page modules
import SelectCard from './modules/selectCard';
import CheqiAmount from './modules/cheqiAmount';
import CardMessage from './modules/cardMessage';
import RecieverName from './modules/recieverName';
import ContactDetails from './modules/contactDetails';
// @import constants
import { createorder_endpoint, productlist_endpoint } from 'config/endpoints';
// @import utils
import { getUserToken, handleAlert, logEvent, uniqueId } from 'utils/functions';
// @import images
import CheqiLogo from 'images/cheqi-postnl.png';
// @import styles
import 'swiper/swiper.min.css';

const logAnalytics = async orderId => {
	logEvent('physical_order_confirmed', {
		user_id: uniqueId,
		order_id: orderId,
		description: 'Order confimation screen physical Cheqi'
	});
};

const getButtonTitle = currentPage => {
	let title;
	switch (currentPage) {
		case 0:
			title = 'kies kaart';
			break;
		case 1:
			title = 'kies bedrag';
			break;
		case 2:
			title = 'schrijf er iets bij';
			break;
		case 3:
			title = 'vul gegevens in';
			break;
		// case 4:
		// 	title = 'vul gegevens in';
		// 	break;
		case 4:
			title = 'controleer & betaal';
			break;
		case 5:
			title = 'versturen';
			break;
		case 6:
			title = 'betalen';
			break;
		default:
			title = 'volgende';
			break;
	}
	return title;
};

const CreateCheqi = () => {
	const history = useHistory();
	// const { bannerData } = useContext(AppContext);

	const swiperRef = useRef(null);
	const childsRef = useRef([]);

	const [data, setData] = useState({
		cardsData: null
		// productsData: null
	});

	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [isButtonEnable, SetButtonEnable] = useState(false);

	useEffect(() => {
		getUserToken(getProductList);
	}, []);

	const getProductList = token => {
		axios
			.get(`${productlist_endpoint}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(response => {
				setData({
					cardsData: response.data.filter(x => {
						return x.digitalProduct;
					})
					// productsData: response.data.filter(x => {
					// 	return !x.digitalProduct;
					// })
				});
				console.log(
					'productsData: ',
					response.data.filter(x => {
						return !x.digitalProduct;
					})
				);
			})
			.catch(error => {
				console.log('get address error:', error);
			});
	};

	const handleClick = () => {
		if (
			childsRef.current.length - 1 > currentPage &&
			childsRef.current[currentPage].isFilled()
		) {
			swiperRef.current.slideNext();
		}

		if (
			childsRef.current.length - 1 === currentPage &&
			childsRef.current[currentPage].isFilled()
		) {
			setLoading(true);
			getUserToken(postCheqi);
		}
	};

	const postCheqi = token => {
		if (!childsRef.current[4].validate()) {
			setTimeout(() => {
				setLoading(false);
			}, 500);
			return;
		}
		axios
			.post(
				createorder_endpoint,
				{
					digitalOrder: false,
					senderEmail: childsRef.current[4].getData().senderEmail,
					senderName: childsRef.current[4].getData().senderFirstName,
					senderMessage: childsRef.current[3].getMessage(),
					amount: childsRef.current[2].getAmount(),
					receiverEmail: childsRef.current[4].getData().receiverEmail,
					receiverPhone: childsRef.current[4].getData().receiverPhone,
					receiverName: childsRef.current[0].getFirstName(),
					receiverLastName: childsRef.current[4].getData().receiverLastName,
					receiverStreet: childsRef.current[4].getData().street,
					receiverHouseNumber: `${childsRef.current[4].getData().houseNumber} ${
						childsRef.current[4].getData().houseNumberAddition
					}`,
					receiverPostalCode: childsRef.current[4].getData().zip,
					receiverCity: childsRef.current[4].getData().city,
					productCodeCard: childsRef.current[1].getCard().productCode,
					deliveryDate: moment(
						childsRef.current[4].getData().deliveryDate
					).format('YYYYMMDD'),
					productCode: childsRef.current[3].isChecked() ? 'ENV001' : ''
					// productCode:
					// 	childsRef.current[4].getCard() !== null
					// 		? childsRef.current[4].getCard().productCode
					// 		: ''
					// productCode:
					// 	childsRef.current[4].getCard() !== null
					// 		? childsRef.current[4].getCard().productCode
					// 		: ''
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)
			.then(function (response) {
				// console.log('create cheqi response:', response.data);
				setLoading(false);
				if (response.data.resultCode === 1000) {
					logAnalytics(response.data.order.orderId);
					history.push({
						pathname: '/pay-cheqi',
						state: { orderId: response.data.order.orderId }
					});
				} else {
					handleAlert();
				}
			})
			.catch(function (err) {
				setLoading(false);
				handleAlert();
				console.log('create cheqi error:', err.response.data.errors);
			});
	};

	const handlePageSelected = useCallback(event => {
		// console.log('pageSelected', event.activeIndex);
		setCurrentPage(event.activeIndex);
		// todo fix bottom line
		if (childsRef.current[event.activeIndex]) {
			SetButtonEnable(childsRef.current[event.activeIndex].isFilled());
		}
	}, []);

	const handleBackButton = useCallback(() => {
		if (currentPage === 0) {
			history.push(`/`);
		} else {
			swiperRef.current.slidePrev();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<>
			<div className={'headerWrapper'}>
				<div className={'headerRow'}>
					<BackButton onPress={handleBackButton} />
					<img src={CheqiLogo} className="cheqiLogo" alt="" />
					{currentPage === 3 && (
						<button
							type="button"
							onClick={() => {
								if (childsRef.current[currentPage].isFilled()) {
									childsRef.current[currentPage].resetComponent();
								}
								swiperRef.current.slideNext();
							}}
							className={'skipButton'}
						>
							<span>overslaan</span>
						</button>
					)}
				</div>
				{/* {currentPage <= 2 && (
					<HeaderBanner title="Sinterklaas: geen verzendkosten" />
				)} */}
				<StepProgress currentStep={currentPage} totalSteps={6} />
			</div>

			{loading && <Loading />}

			{data.cardsData ? (
				<div className="maxFrameSize">
					<Swiper
						initialSlide={0}
						spaceBetween={0}
						slidesPerView={1}
						autoHeight={true}
						allowTouchMove={false}
						onSlideChange={handlePageSelected}
						onSwiper={swiper => (swiperRef.current = swiper)}
					>
						<SwiperSlide>
							<RecieverName
								pageTitle={() => {
									return (
										<strong className="pagerTitle">
											Voor <span className="colorSecondary">wie</span> is het?
										</strong>
									);
								}}
								pageDescription="Aan wie wil je de geldkaart versturen"
								ref={el => (childsRef.current[0] = el)}
								setButtonState={value => {
									SetButtonEnable(value);
								}}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<SelectCard
								pageTitle={() => {
									return (
										<strong className="pagerTitle">
											Kies je <span className="colorSecondary">kaart</span>
										</strong>
									);
								}}
								pageDescription={`Welke kaart wil je aan ${childsRef.current[0]?.getFirstName()} sturen?`}
								data={data.cardsData}
								ref={el => (childsRef.current[1] = el)}
								setButtonState={value => {
									SetButtonEnable(value);
								}}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<CheqiAmount
								pageTitle={() => {
									return (
										<strong className="pagerTitle">
											Kies het <span className="colorSecondary">bedrag</span>{' '}
											dat je wilt geven
										</strong>
									);
								}}
								pageDescription="Maak een keuze uit onderstaande bedragen of kies je eigen bedrag."
								ref={el => (childsRef.current[2] = el)}
								setButtonState={value => {
									SetButtonEnable(value);
								}}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<CardMessage
								pageTitle={() => {
									return (
										<strong className="pagerTitle">
											<span className="colorSecondary">Schrijf</span> er iets
											bij
										</strong>
									);
								}}
								pageDescription={`Schrijf, als je dat wilt, een persoonlijk bericht dat we namens jou op de kaart voor ${childsRef.current[0]?.getFirstName()} schrijven.`}
								firstName={childsRef.current[0]?.getFirstName()}
								ref={el => (childsRef.current[3] = el)}
								setButtonState={value => {
									SetButtonEnable(value);
								}}
							/>
						</SwiperSlide>
						{/* <SwiperSlide>
						<SelectCard
							pageTitle={() => {
								return (
									<Text className={styles.pagerTitle}>
										Kies je{' '}
										<Text className={styles.colorSecondary}>cadeau</Text>
									</Text>
								);
							}}
							pageDescription={`Stuur een leuk cadeau mee voor ${childsRef.current[0]?.getFirstName()}`}
							data={data.productsData}
							ref={el => (childsRef.current[4] = el)}
							setButtonState={value => {
								SetButtonEnable(value);
							}}
						/>
					</SwiperSlide> */}
						<SwiperSlide>
							<ContactDetails
								pageTitle={() => {
									return (
										<strong className="pagerTitle">
											Geldkaart{' '}
											<span className="colorSecondary">versturen</span>
										</strong>
									);
								}}
								userContactDetails={childsRef.current[0]?.getUserDetails()}
								pageDescription={`Vul de gegevens van ${childsRef.current[0]?.getFirstName()} in zodat PostNL de geldkaart kan bezorgen.`}
								receiverFirstName={childsRef.current[0]?.getFirstName()}
								ref={el => (childsRef.current[4] = el)}
								setButtonState={value => {
									if (currentPage === 4) {
										SetButtonEnable(value);
									}
								}}
							/>
						</SwiperSlide>
					</Swiper>
				</div>
			) : (
				<Loading />
			)}

			<div
				className={'buttonsWrapper'}
				style={{ position: 'fixed', pointerEvents: 'none' }}
			>
				<span />
				{/* due to no physical product don't show this text anymore */}
				{/* <p className={'exculsiveText'}>
					{currentPage === 4 &&
						(bannerData.length > 0 && bannerData[0].offerText
							? `* exclusief ${bannerData[0].offerText} verzendkosten`
							: '* exclusief € 2,99 verzendkosten')}
				</p> */}

				<Button
					onClick={handleClick}
					className={'nextButton'}
					text={getButtonTitle(currentPage)}
					theme={isButtonEnable ? 'secondary' : 'disable'}
				/>
			</div>
		</>
	);
};

export default CreateCheqi;
