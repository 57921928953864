/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber } from 'react-intl';

const CheqiOverview = ({
	orderData,
	recieverName,
	message,
	amount,
	product,
	couponCode,
	shippingPrice,
	digitalOrder,
	discountPrice = 0
}) => {
	const calculateTotal = () => {
		let totalAmount = amount + shippingPrice + discountPrice;

		if (product) {
			totalAmount = totalAmount + product.price;
		}

		return totalAmount;
	};

	return (
		<div className="cheqioverviewWrapper">
			<div className={'row'}>
				<div className={'column'}>
					<strong className={['title boldText']}>
						{recieverName ? recieverName : 'Waarde van de geldkaart'}
					</strong>
					<p className={'descr'}>
						{message ? message : 'Dit bedrag verstuur je'}
					</p>
				</div>
				<strong className={'amount'}>
					<FormattedNumber value={amount} style="currency" currency="EUR" />
				</strong>
			</div>

			{product && (
				<div className={'row'}>
					<div className={'column'}>
						<h2 className={'title boldText'}>{product.cmsTitle}</h2>
						<p className={'descr'}>{product.cmsDescription}</p>
					</div>
					<strong className={'amount'}>
						<FormattedNumber
							value={product.price}
							style="currency"
							currency="EUR"
						/>
					</strong>
				</div>
			)}

			{!digitalOrder && (
				<div className={'row'}>
					<div className={'column'}>
						<h2 className={'title boldText'}>Geldkaart en verzending</h2>
						<p className={'descr'}>
							{orderData.receiverStreet} {orderData.receiverHouseNumber}{' '}
							{orderData.receiverPostalCode}, {orderData.receiverCity}
						</p>
					</div>
					<strong className={'amount'}>
						<FormattedNumber
							value={shippingPrice}
							style="currency"
							currency="EUR"
						/>
					</strong>
				</div>
			)}

			{discountPrice !== 0 && (
				<div className={'row'}>
					<div className={'column'}>
						<h2 className={'title boldText'}>Kortingscode</h2>
						<p className={'descr'}>{couponCode}</p>
					</div>
					<strong className={'amount'}>
						<FormattedNumber
							value={discountPrice}
							style="currency"
							currency="EUR"
						/>
					</strong>
				</div>
			)}

			<div className={'row totalWrapper'}>
				<strong className={'column boldText styles.colorWhite'}>
					Te betalen
				</strong>
				<strong className={'boldText styles.colorWhite'}>
					<FormattedNumber
						value={calculateTotal()}
						style="currency"
						currency="EUR"
					/>
				</strong>
			</div>
		</div>
	);
};

export default CheqiOverview;
