import React from 'react';
// @import icons
import MenuButton from './menuIcon';

const Header = ({ title }) => {
	return (
		<div className={'headerWrapper'}>
			<div className={'headerRow'}>
				<MenuButton />
				{title && <h2 className={'pageTitle'}>{title}</h2>}
				<div />
			</div>
		</div>
	);
};

export default Header;
