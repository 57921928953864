import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { paystatus_endpoint } from 'config/endpoints';
// @import components
import Loader from 'components/loader';
// @import config
import { app_url } from 'config/endpoints';
// @import utils
import { getToken } from 'utils/temptoken';
// @import icons
import { ReactComponent as RightArrow } from 'svg/arrowright.svg';
// @import images
// import happyLama from 'images/lama-happy.png';
// import sadLama from 'images/lama-sad.png';
// import waitingLama from 'images/lama-waiting.png';

const getContent = (status, alreadyClaimed) => {
	let description, title, image, background;
	switch (status) {
		case 'failed':
			title = 'Betaling mislukt'; // failed
			description =
				'Er ging iets mis bij het afrekenen. Je kunt opnieuw proberen te betalen.';
			// image = sadLama;
			background = '#f44336';
			break;
		case 'cancelled':
			title = 'Geannuleerd'; // cancelled
			description =
				'De betaling is niet gelukt. Het lijkt erop dat je de betaling hebt geannuleerd. Je kunt opnieuw proberen te betalen.';
			// image = sadLama;
			background = '#de4a00';
			break;
		case 'expired':
			title = 'Zzzzzzz……'; // expired
			description =
				'Je sessie is verlopen, omdat je te lang niets hebt gedaan. Je kunt opnieuw proberen te betalen.';
			// image = waitingLama;
			background = '#23bbe0';
			break;
		case 'pending':
			title = 'Nog even geduld'; // pending
			description =
				'Wij zijn je betaling aan het verwerken. Zodra je betaling is goedgekeurd ontvang je een bericht van ons.';
			// image = waitingLama;
			background = '#66C1C6';
			break;
		case 'completed':
			title = 'Gelukt!'; // completed
			description =
				'Het bedrag staat uiterlijk de eerstvolgende werkdag nadat de ontvanger het bedrag heeft opgehaald op de rekening van de ontvanger.';
			// image = happyLama;
			background = '#1CBC2A';
			break;
		default:
			title = 'Nog even geduld'; // None
			description =
				'Wij zijn je betaling aan het verwerken. Zodra je betaling is goedgekeurd ontvang je een bericht van ons.';
			// image = waitingLama;
			background = '#66C1C6';
			break;

		// default:
		// description = alreadyClaimed
		// 	? 'de geldkaart is opgehaald.'
		// 	: 'Je hebt het bedrag van de geldkaart betaald. Het bedrag staat de volgende werkdag op de rekening van de ontvanger. En de ontvanger heeft van ons een melding hiervan gekregen.';
		// break;
	}
	return { title, description, image, background };
};

const PayResult = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	// const [status, setStatus] = useState(null);
	const [state, setState] = useState({
		status: null,
		alreadyClaimed: null
	});
	const [loading, setLoading] = useState(true);

	let anchorUrl;
	const id = searchParams.get('id');

	// 0, None,
	// 1, Failed,
	// 2, Completed,
	// 4, Cancelled,
	// 8, Expired,
	// 16, Pending

	useEffect(() => {
		if (state.status === null) {
			getToken(id, response => {
				const { token } = response;
				axios
					.get(`${paystatus_endpoint}${id}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(res => {
						console.log(res.data);
						setLoading(false);
						setState({
							...state,
							status: res.data.status,
							alreadyClaimed: res.data.alreadyClaimed
						});
					})
					.catch(function (error) {
						console.log(error);
					});
			});
		}
	}, []); // eslint-disable-line

	if (state.status) {
		// anchorUrl = `com.cheqi.cheqiapp://cheqi/payresult?orderId=${id}&status=${state.status}`;
		// anchorUrl = `cheqi://payresult/${id}/${state.status}`;
		// anchorUrl = 'cheqi://home';
		// anchorUrl = 'cheqi://';
		anchorUrl = app_url;
	}

	return (
		<div className="contentWrapper payresultScreen">
			{loading && <Loader />}

			{state.status && (
				<div
					className="payresultsWrapper"
					style={{ background: getContent(state.status).background }}
				>
					<section className="contentSection">
						<h1>{getContent(state.status).title}</h1>

						<p
							dangerouslySetInnerHTML={{
								__html: getContent(state.status, state.alreadyClaimed)
									.description
							}}
						/>
					</section>
					{/* <img src={getContent(state.status).image} alt="" /> */}
					<a href={anchorUrl} className="buttonStyle">
						Mijn geldkaarten
						<RightArrow />
					</a>
				</div>
			)}
		</div>
	);
};

export default PayResult;
