import React from 'react';
import { useHistory } from 'react-router-dom';
// @import components
import Button from 'modules/button';
// @import images
import { ReactComponent as Next } from 'svg/left-arrow.svg';
import mailCard from 'images/mail-card.png';

const CreateCheqiModal = () => {
	const history = useHistory();
	return (
		<div className={'createCheqiComponent maxFrameSize'}>
			<strong className={'pageTitle pageMainTitle'}>
				Nieuwe geldkaart aanmaken
			</strong>

			<div className={'section'}>
				<div className={'row'}>
					{/* <h3 className={'sectionTitle'}>Cheqi</h3> */}
					<span className={'tagStyle'}>geldkaart incl. verzending €2,99</span>
				</div>

				<p className={'pageContent'}>
					Verstuur nu je geldkaart per post, rechtstreeks naar de ontvanger of
					eerst naar jezelf. PostNL bezorgt de geldkaart op de gewenste dag.
				</p>
				<img className={'giftCard'} src={mailCard} alt="" />
				<Button
					text="Verstuur geldkaart"
					theme="secondary"
					onClick={async () => {
						history.push(`/create-cheqi`);
					}}
					icon={<Next />}
					className={'nextButton'}
					justifyContent="space-between"
				/>
			</div>
		</div>
	);
};

export default CreateCheqiModal;
