export const privacyUrl =
  'https://www.cheqi.nl/gebruiksvoorwaarden?mode=webview';

export const cheqiUrl = 'https://www.cheqi.nl';
// export const webappurl = ' https://accept.cheqi.app/'; // for localtest/accept
// export const webappurl = ' https://cheqi.app/'; // for live
export const webappurl = ' https://www2.cheqi.app/'; // for production

export const faqUrl = 'https://www.cheqi.nl/#vragen';
export const mailtoUrl = 'help@cheqi.nl';

export const cms_api_url =
  'https://api-eu-central-1.graphcms.com/v2/cknya5sluarg001wd382nbx06/master';
