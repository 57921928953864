import React, { useRef, useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// @import images
import intro1 from 'images/intro-images/intro-1.png';
import intro2 from 'images/intro-images/intro-2.png';
import intro3 from 'images/intro-images/intro-3.png';
import intro4 from 'images/intro-images/intro-4.png';
import intro5 from 'images/intro-images/intro-5.png';

const data = [
	{
		id: 1,
		image: intro1
	},
	{
		id: 2,
		image: intro2
	},
	{
		id: 3,
		image: intro3
	},
	{
		id: 4,
		image: intro4
	},
	{
		id: 5,
		image: intro5
	}
];

const Introduction = ({ closePopup }) => {
	const swiperRef = useRef(null);
	const [state, setstate] = useState({
		index: 0,
		showSkip: true
	});

	const handlePageSelected = useCallback(event => {
		setstate({
			showSkip: true,
			index: event.activeIndex
		});
	}, []);

	const handleNavigate = () => {
		closePopup();
	};

	return (
		<div className={'introContainer'}>
			{state.showSkip && (
				<button type="button" onClick={handleNavigate} className={'skipButton'}>
					<span className={'skipText'}>Klaar</span>
				</button>
			)}
			<Swiper
				initialSlide={0}
				spaceBetween={0}
				slidesPerView={1}
				allowTouchMove={true}
				onSwiper={swiper => (swiperRef.current = swiper)}
				onSlideChange={handlePageSelected}
			>
				{data.map((item, index) => {
					return (
						<SwiperSlide key={index}>
							<div key={index} className={'imageWrapper'}>
								<img src={item.image} className={'introImage'} alt="" />
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<button
				type="button"
				onClick={() => {
					if (state.index < 4) {
						swiperRef.current.slideNext();
					} else {
						if (state.showSkip) {
							handleNavigate();
						}
					}
				}}
				className={'nextButton'}
			>
				<span className={'nextText'}>
					{state.index < 4 ? 'volgende' : state.showSkip ? 'begin' : 'volgende'}
				</span>
			</button>
		</div>
	);
};

export default Introduction;
