export const textColor = '#000000';
export const primaryColor = '#f5f2f2';
export const secondaryColor = '#ED8C00';

export const greenColor = '#1CBC2A';
export const activeColor = secondaryColor;
export const backgroundColor = primaryColor;

export const buttonPrimaryColor = '#ebe5e5';
export const buttonSecondaryColor = secondaryColor;
export const buttonActiveColor = '#de4a00';
export const buttonDisableColor = '#cccccc';
export const placeholderTextColor = '#cccccc';
export const borderColor = '#cccccc';

export const dotsColor = '#b19a9b';
