import React, {
	useState,
	useEffect
	// useRef,
	// useCallback,
	// useMemo
} from 'react';
import axios from 'axios';
// import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
// import components
import Modal from 'components/Modal';
import IntroBanner from 'components/introBanner';
// @import modules
import Button from 'modules/button';
import Loading from 'modules/loading';
import MenuButton from 'modules/menuIcon';
// import HelpButton from 'modules/helpIcon';
import OrderCard from 'modules/orderCard';
import CreateCheqiModal from './createCheqiModal';
// import HeaderBanner from 'modules/headerBanner';
// import CircleTransition from 'modules/circleTransition';
// import styles
import 'css/pages/home.scss';
// import { styles } from '../../constants/styles';
// import constants
// import { secondaryColor } from '../../constants/colors';
import { orderlist_endpoint } from 'config/endpoints';
// import utils
import { getUserToken } from 'utils/functions';
// import images
import CheqiLogo from 'images/cheqi-postnl.png';
import { ReactComponent as Add } from 'svg/add.svg';
// import animations
// import * as lottieAnimation from 'animations/animation_1.json';

// const getOptions = (animationName, loop = false) => {
// 	return {
// 		loop: loop,
// 		autoplay: true,
// 		animationData: animationName.default,
// 		rendererSettings: {
// 			preserveAspectRatio: 'xMidYMid slice'
// 		}
// 	};
// };

// const EmptyComponent = ({ type, handleCreateCheqi }) => {
// 	return (
// 		<div
// 			onClick={() => {
// 				handleCreateCheqi();
// 			}}
// 			className="centerContainer emptyWrapper"
// 		>
// 			<Lottie
// 				options={getOptions(lottieAnimation)}
// 				className="lottieImage"
// 				width={150}
// 				height={150}
// 			/>
// 			<strong className="pagerTitle colorSecondary">Stuur</strong>
// 			<strong className="pagerTitle">
// 				{type === 'digital'
// 					? 'nu je  \n eerste e-Cheqi'
// 					: 'nu je \n eerste Cheqi'}
// 			</strong>
// 		</div>
// 	);
// };

const Home = () => {
	// const circleTransition = useRef(null);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [modalVisible, setModalVisible] = useState(false);
	// const [isOpen, SetOpen] = useState(false);
	// const [refresh, setRefresh] = useState(false);
	const [cheqilist, setCheqilist] = useState({
		// digitalCheqi: [],
		physicalCheqi: []
	});
	const [isConnected, setisConnected] = useState(true);
	// const [state, setState] = useState({
	// 	customTopMargin: 0,
	// 	customLeftMargin: 0,
	// 	circleColor: 'rgba(0,0,0,0.88)'
	// });

	// const rotateButton = () => {
	// 	SetOpen(!isOpen);
	// };

	const handleCreateCheqi = event => {
		history.push(`/create-cheqi`);
		// if (isConnected) {
		// 	let pressLocationX = event.nativeEvent.pageX;
		// 	let pressLocationY = event.nativeEvent.pageY;
		// 	setState({
		// 		...state,
		// 		customLeftMargin: pressLocationX,
		// 		customTopMargin: pressLocationY
		// 	});
		// 	circleTransition.current.start(() => {});
		// 	rotateButton();
		// }
	};

	useEffect(() => {
		getUserToken(fetchOrders);

		// todo create hooke to check netinfo
		// const unsubscribe = NetInfo.addEventListener(netState => {
		// 	setisConnected(netState.isConnected);
		// });

		return () => {
			// unsubscribe();
		};
	}, []);

	const fetchOrders = token => {
		axios
			.get(orderlist_endpoint, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(function (response) {
				console.log('fetch order response:', response.data);
				setLoading(false);
				// setRefresh(false);
				setCheqilist({
					// digitalCheqi: response.data.filter(x => {
					// 	return x.digitalOrder;
					// }),
					physicalCheqi: response.data.filter(x => {
						return !x.digitalOrder;
					})
				});
			})
			.catch(function (error) {
				setLoading(false);
				// setRefresh(false);
				console.log('fetch order error:', error);
			});
	};

	const fetchNetInfo = () => {
		setLoading(true);
		// NetInfo.fetch().then(netState => {
		setTimeout(() => {
			setLoading(false);
			setisConnected(true);
		}, 500);
		// });
	};

	// const createCheqiBackground = useMemo(() => {
	// 	return (
	// 		<>
	// 			<div pointerEvents="none" className={'circleContainer'}>
	// 				<CircleTransition
	// 					duration={300}
	// 					expand={!isOpen}
	// 					position="custom"
	// 					// easing={Easing.ease}
	// 					ref={circleTransition}
	// 					color={state.circleColor}
	// 					customTopMargin={state.customTopMargin}
	// 					customLeftMargin={state.customLeftMargin}
	// 				/>
	// 			</div>
	// 			{isOpen && (
	// 				<CreateCheqiModal
	// 					navigation={navigation}
	// 					closeModal={() => {
	// 						rotateButton();
	// 						circleTransition.current.start(() => {}, 300);
	// 					}}
	// 				/>
	// 			)}
	// 		</>
	// 	);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isOpen]);

	return (
		<>
			<div className="headerWrapper">
				<div className="headerRow">
					<MenuButton />
					<img src={CheqiLogo} className="cheqiLogo" alt="" />
					{/* <HelpButton
						onClick={() => {
							// todo: open introduction carousel
							setModalVisible(true);
						}}
					/> */}
				</div>
				{/* <HeaderBanner title="Sinterklaas: geen verzendkosten" /> */}
			</div>

			{loading ? (
				<Loading />
			) : (
				<div className={'pageWrapper'}>
					{!isConnected ? (
						<div className={'centerContainer'}>
							<button
								className={'refreshWrapper'}
								onPress={() => {
									fetchNetInfo();
								}}
							>
								{/* <Image
										source={require('../../images/refresh.png')}
										className={refreshIcon}
									/> */}
								<p className={'pagerDescription textMargin'}>
									Vernieuw deze lijst met Cheqi's
								</p>
							</button>
						</div>
					) : (
						<div className="cheqiListWrapper">
							{cheqilist.physicalCheqi.length > 0 ? (
								<>
									<strong className={'pageTitle pageMainTitle'}>
										Jouw geldkaarten
									</strong>
									{cheqilist.physicalCheqi.map((item, index) => (
										<OrderCard key={`order_${index}`} item={item} />
									))}
								</>
							) : (
								// <EmptyComponent
								// 	type="physical"
								// 	text="Stuur nu je eerste Cheqi per post"
								// 	handleCreateCheqi={handleCreateCheqi}
								// />
								<CreateCheqiModal />
							)}
						</div>
					)}
				</div>
			)}

			{/* {createCheqiBackground} */}
			{cheqilist.physicalCheqi.length > 0 && (
				<Button
					className={'addButton'}
					imgClass={'addIconStyle'}
					onClick={handleCreateCheqi}
					theme={!isConnected ? 'disable' : 'secondary'}
					icon={<Add className="iconStyle" fill="#fff" />}
				/>
			)}

			<Modal handleClose={() => setModalVisible(false)} isOpen={modalVisible}>
				<IntroBanner closePopup={() => setModalVisible(false)} />
			</Modal>
		</>
	);
};

export default Home;
