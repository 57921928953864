import React from 'react';
import { ReactComponent as ActivityIndicator } from 'svg/oval.svg';

const Loading = ({ style = {} }) => {
	return (
		<div className="loadingWrapper" style={style}>
			<ActivityIndicator width="40" />
		</div>
	);
};

export default Loading;
