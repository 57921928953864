import React from 'react';

const PageData = ({ pageTitle, pageDescription }) => {
	return (
		<>
			{pageTitle()}
			{pageDescription && (
				<p className={'pagerDescription'}>{pageDescription}</p>
			)}
		</>
	);
};

export default PageData;
