import Pickup from 'pages/Pickup';
import Claimed from 'pages/Claimed';
import CouponPayment from 'pages/CouponPayment';
import PayResult from 'pages/PayResult';
import Home from 'pages/home';
import Contact from 'pages/contact';
import PayCheqi from 'pages/home/payCheqi';
import CheqiPayResult from 'pages/home/payResult';
// import SendCheqi from 'pages/home/sendCheqi';
import PhysicalCheqi from 'pages/home/physicalCheqi';
import Forms from 'pages/Forms';
import SenderPayment from 'pages/SenderPayment';
import PageNotFound from 'pages/PageNotFound';

const maintenance = localStorage.getItem('maintenance');

const routes = [
	{
		path: '/',
		name: 'Home',
		exact: true,
		component: Home,
		isVisible: maintenance
	},
	{
		path: '/contact',
		name: 'Contact',
		exact: true,
		component: Contact,
		isVisible: maintenance
	},
	// {
	// 	path: '/send-cheqi',
	// 	name: 'SendCheqi',
	// 	exact: true,
	// 	component: SendCheqi
	// },
	{
		path: '/create-cheqi',
		name: 'Physical Cheqi',
		exact: true,
		component: PhysicalCheqi,
		isVisible: maintenance
	},
	{
		path: '/pay-cheqi',
		name: 'Pay Cheqi',
		exact: true,
		component: PayCheqi,
		isVisible: maintenance
	},
	{
		path: '/pay-result',
		name: 'Pay Result',
		exact: true,
		component: CheqiPayResult,
		isVisible: maintenance
	},
	// {
	// 	// confirm with Gertjan it's better to use home page as your cheqi
	// 	path: '/jouw-cheqi',
	// 	name: 'YourCheqi',
	// 	exact: true,
	// 	component: YourCheqi
	// },
	{
		path: '/payresult',
		name: 'Pay Result',
		component: PayResult,
		isVisible: true
	},
	{
		path: '/senderpayment',
		name: 'Sender Payment',
		component: SenderPayment,
		isVisible: maintenance
	},
	{
		path: '/pickup',
		name: 'Pickup',
		component: Pickup,
		isVisible: true
	},
	{
		path: '/claimed',
		name: 'Claimed',
		component: Claimed,
		isVisible: true
	},
	{
		path: '/couponpayment',
		name: 'Coupon Payment',
		component: CouponPayment,
		isVisible: maintenance
	},
	{
		path: '/eenvraag',
		component: Forms,
		isVisible: maintenance
	},
	{
		path: '/404',
		component: PageNotFound,
		isVisible: false
	}
];

export default routes;
